import { useApi } from '@contexts/ApiContext';
import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChartDisplayType, useSettings } from '@contexts/SettingsContext';
import Button, { ButtonType } from '@components/topstep/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import HelpMessage from '@components/helpMessage';
import { useTradingAccount } from '@contexts/TradingAccountContext';
import { IUserSettingsModel, JsonDocument, PersonalDailyAction, PersonalDailyLimitsRequest, RootElement, TradingAccountType } from '@api/userApi';
import { networkSpeed, chartPlotSide, topNavTextSize } from 'src/data/enumTypeMaps';
import { logException } from '@/helpers/exceptionHelper';
import { toast } from 'react-toastify';
import styles from './settings.module.scss';
import { StyledButton } from '@/components/styledComponents';
import { set } from 'lodash';
import { HexColorPicker } from "react-colorful";

interface ChartSettingsProps {
  onSaved?: () => void;
  onCancelled?: () => void;
  onClosed?: () => void;
  showButtons?: boolean;
  showClose?: boolean;
}

const ChartSettings: React.FC<ChartSettingsProps> = ({ onSaved, onCancelled, showButtons, showClose, onClosed }) => {
  const settings = useSettings();
  const { activeTradingAccount } = useTradingAccount();

  const [hidePositionPlots, setHidePositionPlots] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentNetworkSpeed, setCurrentNetworkSpeed] = useState<networkSpeed | undefined>();
  const [chartDisplayType, setChartDisplayType] = useState<ChartDisplayType | undefined>();
  const [plotSide, setPlotSide] = useState<chartPlotSide | undefined>();
  const [showChartExecutions, setShowChartExecutions] = useState<boolean>(false);
  const [hideEconomicEvents, setHideEconomicEvents] = useState<boolean>(false);
  const [executionColor, setExecutionColor] = useState<string>(settings.customSettings?.executionColor ?? '#fff');
  const [showExecutionColorPicker, setShowExecutionColorPicker] = useState(false);

  const hasDll = useMemo(() => {
    return activeTradingAccount.type == TradingAccountType.Express || activeTradingAccount.type == TradingAccountType.Live;
  }, [activeTradingAccount.type]);

  useEffect(() => {
    setHidePositionPlots(settings.customSettings.hidePositionPlots);
    setCurrentNetworkSpeed(settings.customSettings?.networkSpeedNew);
    setPlotSide(settings.customSettings.positionPlotsSide);
    setChartDisplayType(settings.customSettings.chartDisplayType);
    setShowChartExecutions(settings.customSettings.showChartExecutions);
    setHideEconomicEvents(settings.customSettings.hideEconomicEvents);
    setExecutionColor(settings.customSettings.executionColor ?? '#fff');
  }, [settings]);

  const cancel = useCallback(() => {
    setHidePositionPlots(settings.customSettings.hidePositionPlots);
    setCurrentNetworkSpeed(settings.customSettings?.networkSpeedNew);
    setPlotSide(settings.customSettings.positionPlotsSide);
    setChartDisplayType(settings.customSettings.chartDisplayType);
    setShowChartExecutions(settings.customSettings.showChartExecutions);
    setHideEconomicEvents(settings.customSettings.hideEconomicEvents);
    setExecutionColor(settings.customSettings.executionColor ?? '#fff');
    onCancelled && onCancelled();
  }, [settings]);

  const onSave = useCallback(() => {
    settings.customSettings.networkSpeedNew = currentNetworkSpeed;
    settings.customSettings.hidePositionPlots = hidePositionPlots;
    settings.customSettings.positionPlotsSide = plotSide;
    settings.customSettings.chartDisplayType = chartDisplayType;
    settings.customSettings.showChartExecutions = showChartExecutions;
    settings.customSettings.hideEconomicEvents = hideEconomicEvents;
    settings.customSettings.executionColor = executionColor;

    console.log('settings.customSettings', settings.customSettings);

    setIsLoading(true);

    Promise.all([settings.saveCustomSettings(settings.customSettings)])
      .then(() => {
        setIsLoading(false);
        onSaved && onSaved();
        toast('Settings saved', { type: 'success', hideProgressBar: true});
      })
      .catch((e) => {
        setIsLoading(false);
        logException(e, 'Error saving settings');
        toast('Error saving settings', { type: 'error', hideProgressBar: true });
      });
  }, [executionColor, activeTradingAccount.accountId, hidePositionPlots, plotSide, currentNetworkSpeed, hideEconomicEvents, chartDisplayType, settings.saveCustomSettings, settings.customSettings, onSaved, showChartExecutions]);

  const updateNetworkSpeed = useCallback((input: string) => {
    setCurrentNetworkSpeed(parseInt(input) as networkSpeed);
  }, []);

  const updatePlotSide = useCallback((input: string) => {
    setPlotSide(parseInt(input) as chartPlotSide);
  }, []);

  const updateChartDisplayType = useCallback((input: string) => {
    setChartDisplayType(parseInt(input) as ChartDisplayType);
  }, []);

  const isChanged = useMemo(() => {
    return (
      hidePositionPlots !== settings.customSettings.hidePositionPlots ||
      plotSide !== settings.customSettings.positionPlotsSide ||
      currentNetworkSpeed !== settings.customSettings?.networkSpeedNew ||
      chartDisplayType !== settings.customSettings.chartDisplayType ||
      showChartExecutions !== settings.customSettings.showChartExecutions || 
      hideEconomicEvents !== settings.customSettings.hideEconomicEvents ||
      executionColor !== settings.customSettings.executionColor
    );
  }, [
    hidePositionPlots,
    plotSide,
    settings,
    settings.customSettings,
    activeTradingAccount.personalDailyLossLimit,
    activeTradingAccount.personalDailyLossLimitAction,
    activeTradingAccount.personalDailyProfitTarget,
    activeTradingAccount.personalDailyProfitTargetAction,
    chartDisplayType,
    currentNetworkSpeed,
    showChartExecutions,
    hideEconomicEvents,
    executionColor
  ]);

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          // alignContent: 'space-around',
          paddingBottom: 0,
          paddingTop: 0,
          maxWidth: '100%',
          overflowY: 'auto'
        }}
      >
        <FormGroup sx={{ margin: '1em', marginBottom: 0, marginTop: 0, width: '100%', maxWidth: '30em' }}>
          <div>
            ⚠️<span style={{ color: '#a9a9a9', fontSize: '.8rem' }}>= Use at own risk.</span>
          </div>
        </FormGroup>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          // alignContent: 'space-around',
          paddingBottom: '1em',
          paddingTop: 0,
          maxWidth: '100%',
          overflowY: 'auto'
        }}
      >
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Data Update Speed
            <HelpMessage message='The speed at which the market data updates. Fast = 50ms, Medium = 250ms, Slow = 750ms. Use slower setting if you are having lag issues' />
          </FormLabel>
          <Select
            variant='outlined'
            type='number'
            value={currentNetworkSpeed === undefined ? networkSpeed.Medium : currentNetworkSpeed}
            style={{ margin: '1em 0' }}
            onChange={(t) => updateNetworkSpeed(t.target.value + '')}
          >
            <MenuItem value={networkSpeed.Low} key={networkSpeed.Low}>
              Slow
            </MenuItem>
            <MenuItem value={networkSpeed.Medium} key={networkSpeed.Medium}>
              Medium
            </MenuItem>
            <MenuItem value={networkSpeed.High} key={networkSpeed.High}>
              Fast
            </MenuItem>
          </Select>
        </FormGroup>
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Hide Chart Plots
            <HelpMessage message='Hide Chart Plots (order lines, position lines, etc)' />
          </FormLabel>
          <FormControlLabel control={<Checkbox checked={hidePositionPlots} onChange={() => setHidePositionPlots(!hidePositionPlots)} />} label='Hide Chart plots - All ⚠️' />
        </FormGroup>
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Chart Plot Alignment
            <HelpMessage message='Plot chart items left or right' />
          </FormLabel>
          <Select
            variant='outlined'
            type='number'
            value={plotSide === undefined ? chartPlotSide.Right : plotSide}
            style={{ margin: '1em 0' }}
            onChange={(t) => updatePlotSide(t.target.value + '')}
            disabled={hidePositionPlots}
          >
            <MenuItem value={chartPlotSide.Left} key={chartPlotSide.Left}>
              Left
            </MenuItem>
            <MenuItem value={chartPlotSide.Right} key={chartPlotSide.Right}>
              Right
            </MenuItem>
          </Select>
        </FormGroup>
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Chart Value Display Type
            <HelpMessage message='Shows positions in ticks, percent or dollar values.' />
          </FormLabel>
          <Select
            variant='outlined'
            type='number'
            value={chartDisplayType === undefined ? ChartDisplayType.Dollar : chartDisplayType}
            style={{ margin: '1em 0' }}
            onChange={(t) => updateChartDisplayType(t.target.value + '')}
            disabled={hidePositionPlots}
          >
            <MenuItem value={ChartDisplayType.Dollar} key={ChartDisplayType.Dollar}>
              Dollar
            </MenuItem>
            <MenuItem value={ChartDisplayType.Tick} key={ChartDisplayType.Tick}>
              Ticks
            </MenuItem>
            <MenuItem value={ChartDisplayType.Percent} key={ChartDisplayType.Percent}>
              Percent
            </MenuItem>
          </Select>
        </FormGroup>
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Chart Executions
            <HelpMessage message='Show or hide trade executions on charts.' />
          </FormLabel>
          <FormControlLabel control={<Checkbox checked={showChartExecutions} onChange={() => setShowChartExecutions(!showChartExecutions)} />} label='Show Chart Executions' />
          {showChartExecutions && (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <FormLabel>Executions Color</FormLabel>
              <div style={{ cursor: 'pointer',marginLeft: 10, width: 40, height: 20, borderRadius: 4, background: executionColor }} onClick={() => setShowExecutionColorPicker(true)} />
            </Box>
          )}
        </FormGroup>
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Hide Economic Events
            <HelpMessage message='Hide Economic Events on the chart.' />
          </FormLabel>
          <FormControlLabel control={<Checkbox checked={hideEconomicEvents} onChange={() => setHideEconomicEvents(!hideEconomicEvents)} />} label='Hide Economic Events' />
        </FormGroup>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {(isChanged || showButtons || showClose) && (
          <StyledButton color='error' disabled={isLoading} onClick={showClose && !isChanged ? onClosed : cancel} sx={{ margin: '1em' }}>
            {showClose && !isChanged ? 'Close' : 'Cancel'}
          </StyledButton>
        )}
        {isChanged && (
          <StyledButton color='success' disabled={isLoading} onClick={onSave} sx={{ margin: '1em' }}>
            Save {isLoading && <FontAwesomeIcon spin spinPulse icon={faSpinner} />}
          </StyledButton>
        )}
      </Box>

      {showExecutionColorPicker && (
        <div className={styles.colorPickerOverlayBg} onClick={() => setShowExecutionColorPicker((prev) => !prev)}>
          <div onClick={(e) => e.stopPropagation()}>
            <HexColorPicker color={executionColor} onChange={(color) => setExecutionColor(color)} />
          </div>
        </div>
      )}
    </Box>
  );
};

export default ChartSettings;
