import { QuotesProvider } from "./quotesProvider";

type QuotesSubscriber = {
    symbols: string[];
    fastSymbols: string[];
    listener: (e) => void;
};

type QuotesPulseProviderTimers = {
    fastTimer: number;
    generalTimer: number;
};

export class QuotesPulseProvider {
    
    private _subscribers: { [guid: string]: QuotesSubscriber };
    private _timers: QuotesPulseProviderTimers | null;
    private _quotesProvider: QuotesProvider;
    private _requestsPending: number;

    constructor(quotesProvider: QuotesProvider) {
        this._subscribers = {};
        this._timers = null;
        this._quotesProvider = quotesProvider;
        this._requestsPending = 0;
    }

    subscribeQuotes(symbols: string[], fastSymbols: string[], onRealtimeCallback: (e) => void, listenerGuid: string) {
        this._subscribers[listenerGuid] = {
            symbols: symbols,
            fastSymbols: fastSymbols,
            listener: onRealtimeCallback,
        };
        this._createTimersIfRequired();
        //console.debug(`QuotesPulseProvider: subscribed quotes with #${listenerGuid}`);
    }

    unsubscribeQuotes(listenerGuid: string) {
        delete this._subscribers[listenerGuid];
        if (Object.keys(this._subscribers).length === 0) {
            this._destroyTimers();
        }
        //console.debug(`QuotesPulseProvider: unsubscribed quotes with #${listenerGuid}`);
    }

    _createTimersIfRequired() {
        if (this._timers === null) {
            const fastTimer = window.setInterval(this._updateQuotes.bind(this, 1 /* SymbolsType.Fast */), 10000 /* UpdateTimeouts.Fast */);
            const generalTimer = window.setInterval(this._updateQuotes.bind(this, 0 /* SymbolsType.General */), 60000 /* UpdateTimeouts.General */);
            this._timers = { fastTimer, generalTimer };
        }
    }

    _destroyTimers() {
        if (this._timers !== null) {
            clearInterval(this._timers.fastTimer);
            clearInterval(this._timers.generalTimer);
            this._timers = null;
        }
    }

    _updateQuotes(updateType: number) {
        if (this._requestsPending > 0) {
            return;
        }
        // eslint-disable-next-line guard-for-in
        for (const listenerGuid in this._subscribers) {
            this._requestsPending++;
            const subscriptionRecord = this._subscribers[listenerGuid];
            
            this._quotesProvider.getQuotes(updateType === 1 /* SymbolsType.Fast */ ? subscriptionRecord.fastSymbols : subscriptionRecord.symbols)
                .then((data) => {
                this._requestsPending--;
                if (!this._subscribers.hasOwnProperty(listenerGuid)) {
                    return;
                }
                subscriptionRecord.listener(data);
                //console.debug(`QuotesPulseProvider: data for #${listenerGuid} (${updateType}) updated successfully, pending=${this._requestsPending}`);
            })
                .catch((reason) => {
                this._requestsPending--;
                //console.debug(`QuotesPulseProvider: data for #${listenerGuid} (${updateType}) updated with error=${reason}, pending=${this._requestsPending}`);
            });
        }
    }
}