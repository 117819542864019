import { IPositionModel, SymbolMetadata, UpdateStopLossRequest } from '@/api/userApi';
import { IChartingLibraryWidget, IOrderLineAdapter } from '@/charting_library/charting_library';
import { useApi } from '@/contexts/ApiContext';
import { calculatePnl, useOrders } from '@/contexts/OrdersContext';
import { ChartDisplayType, useSettings } from '@/contexts/SettingsContext';
import { useSymbol } from '@/contexts/SymbolContext';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { chartPlotSide } from '@/data/enumTypeMaps';
import { roundToTickSize } from '@/helpers/decimalHelper';
import { logException } from '@/helpers/exceptionHelper';
import { formatPrice } from '@/helpers/formatter';
import { ChartGroup, ChartInstanceData, LEFT_PLOT_SIDE, ORDER_LEFT_PLOT_SIDE, ORDER_RIGHT_PLOT_SIDE, RIGHT_PLOT_SIDE } from '@/views/trader/components/charts/chartTypes';
import Decimal from 'decimal.js';
import React, { useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

interface ChartPositionProps {
  widget: IChartingLibraryWidget;
  charts: ChartGroup[];
}

const ChartPositions: React.FC<ChartPositionProps> = ({ widget, charts }): JSX.Element => {
  const { rawActivePositions, editSltpSetting, closePosition } = useOrders();
  const { orderApi } = useApi();
  const { customSettings } = useSettings();
  const { getContractById } = useSymbol();
  const { activeTradingAccount } = useTradingAccount();
  const canTrade = useRef<boolean>(true);
  const currentChartDisplayType = useRef<ChartDisplayType>(customSettings.chartDisplayType ?? ChartDisplayType.Dollar);


  useEffect(() => {
    currentChartDisplayType.current = customSettings.chartDisplayType ?? ChartDisplayType.Dollar;
  }, [customSettings.chartDisplayType]);

  useEffect(() => {
    canTrade.current = activeTradingAccount.isFollower !== true;
  }, [activeTradingAccount]);

  useEffect(() => {
    const updateFunc = (grp: ChartGroup) => {
      for (const chart of grp.charts) {
        if (chart.positionLine) {
          updatePositionLineText(chart, grp.metadata, grp.lastPriceForPnl, chart.positionLine, currentChartDisplayType.current);
        }
      }
    };
    for (const chart of charts) {
      chart.eventHandlers.push(updateFunc);
    }

    return () => {
      for (const grp of charts) {
        grp.eventHandlers.splice(grp.eventHandlers.indexOf(updateFunc), 1);

        for (const chartData of grp.charts) {
          try {
            if (chartData.positionLine) chartData.positionLine.remove();
          } catch (e) {
            console.warn('Error removing position line', e);
          }
          try {
            if (chartData.stopLossLine) chartData.stopLossLine.remove();
          } catch (e) {
            console.warn('Error removing stop loss line', e);
          }
          try {
            if (chartData.takeProfitLine) chartData.takeProfitLine.remove();
          } catch (e) {
            console.warn('Error removing take profit line', e);
          }
        }
      }
    };
  }, [charts]);

  const updateTakeProfitLine = useCallback((chartGroup: ChartGroup, chartInstance: ChartInstanceData, pos: IPositionModel, plotSide: chartPlotSide, chartDisplayType: ChartDisplayType) => {
    const takeProfit = pos.takeProfit;
    const toMake = pos.toMake;

    let text = '';

    switch (chartDisplayType) {
      case ChartDisplayType.Dollar:
        text = '+$' + toMake.toFixed(2);
        break;
      case ChartDisplayType.Percent:
        {
          const rounded = roundToTickSize(chartInstance.lastAvgPrice, chartGroup.metadata.tickSize);

          var currentPrice = new Decimal(takeProfit).sub(rounded).mul(100).div(rounded).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
          text = currentPrice.toFixed(2) + '%';
        }
        break;
      case ChartDisplayType.Tick:
        const rounded = roundToTickSize(chartInstance.lastAvgPrice, chartGroup.metadata.tickSize);
        var ticks = new Decimal(pos.takeProfit).sub(rounded).div(chartGroup.metadata.tickSize);
        text = ticks.toFixed(0) + ' ticks';
        break;
    }

    chartInstance.takeProfitLine
      .setPrice(takeProfit)
      .setQuantity((pos.positionSize * -1).toString())
      .setText(text)
      .setLineLength(plotSide == 0 ? ORDER_LEFT_PLOT_SIDE : ORDER_RIGHT_PLOT_SIDE);
  }, []);

  const updateStopLossLine = useCallback((chartGroup: ChartGroup, chartInstance: ChartInstanceData, pos: IPositionModel, plotSide: chartPlotSide, chartDisplayType: ChartDisplayType) => {
    const risk = pos.risk;
    const stopLoss = pos.stopLoss;

    let text = risk < 0 ? '+$' : '-$';
    let color = risk < 0 ? '#00ff00' : '#ff0000';

    switch (chartDisplayType) {
      case ChartDisplayType.Dollar:
        text += Math.abs(risk).toFixed(2);
        color = risk < 0 ? '#00ff00' : '#ff0000';
        break;
      case ChartDisplayType.Percent:
        {
          const rounded = roundToTickSize(chartInstance.lastAvgPrice, chartGroup.metadata.tickSize);

          var currentPrice = new Decimal(stopLoss).sub(rounded).mul(100).div(rounded).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
          text = currentPrice.toFixed(2) + '%';
        }
        break;
      case ChartDisplayType.Tick:
        const rounded = roundToTickSize(chartInstance.lastAvgPrice, chartGroup.metadata.tickSize);
        var ticks = new Decimal(pos.stopLoss).sub(rounded).div(chartGroup.metadata.tickSize);
        text = ticks.toFixed(0) + ' ticks';
        break;
    }

    chartInstance.stopLossLine
      .setPrice(stopLoss)
      .setText(text)
      .setBodyBackgroundColor(color)
      .setLineColor(color)
      .setLineLength(plotSide == 0 ? ORDER_LEFT_PLOT_SIDE : ORDER_RIGHT_PLOT_SIDE)
      .setQuantityBackgroundColor(color)
      .setQuantity((pos.positionSize * -1).toString());
  }, []);

  const createStopLossLine = useCallback(
    (chartGrp: ChartInstanceData, pos: IPositionModel, tickSize: number, plotSide: chartPlotSide) => {
      let stopLossLine: IOrderLineAdapter = null;
      try {
        stopLossLine = chartGrp.chart.createOrderLine({ disableUndo: true });
      } catch (e) {
        console.log('Error creating line', e);
        return;
      }

      const risk = pos.risk;
      const stopLoss = pos.stopLoss;

      if (stopLoss && !isNaN(risk) && risk != null) {
        let sign = risk < 0 ? '+' : '';
        let color = risk < 0 ? '#00ff00' : '#ff0000';
        const posSize = pos.positionSize;
        const symbolData = getContractById(pos.symbolId);

        stopLossLine
          .setPrice(stopLoss)
          .setLineStyle(2)
          .setBodyBorderColor('#000')
          .setBodyBackgroundColor(color)
          .setBodyTextColor('#000')
          .setQuantityTextColor('#000')
          .setQuantityBorderColor('#000')
          .setQuantityBackgroundColor(color)
          .setCancelButtonBorderColor('#000')
          .setCancelButtonIconColor('#000')
          .setLineColor(color)
          .setLineLength(plotSide == 0 ? ORDER_LEFT_PLOT_SIDE : ORDER_RIGHT_PLOT_SIDE)
          .setQuantity((posSize * -1).toString());

        if (!activeTradingAccount.isFollower) {
          stopLossLine
            .onMoving(function () {
              if (!canTrade.current) {
                return;
              }

              if (symbolData) {
                const price = stopLossLine.getPrice();
                if (symbolData) {
                  let text = '';
                  switch (currentChartDisplayType.current) {
                    case ChartDisplayType.Dollar:
                      text = `$${formatPrice(calculatePnl(chartGrp.lastSize, chartGrp.lastAvgPrice, price, symbolData.tickValue / tickSize))}`;
                      break;
                    case ChartDisplayType.Percent:
                      {
                        const rounded = roundToTickSize(chartGrp.lastAvgPrice, tickSize);

                        var currentPrice = new Decimal(price).sub(rounded).mul(100).div(rounded).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
                        text = currentPrice.toFixed(2) + '%';
                      }
                      break;
                    case ChartDisplayType.Tick:
                      {
                        const rounded = roundToTickSize(chartGrp.lastAvgPrice, tickSize);
                        var ticks = new Decimal(price).sub(rounded).div(tickSize);
                        text = ticks.toFixed(0) + ' ticks';
                      }
                      break;
                  }

                  stopLossLine.setText(sign + text);
                }
              }
            })
            .onCancel(function () {
              if (canTrade.current) {

                editSltpSetting(pos.id, null, chartGrp.lastTakeProfit).then((res) => {
                  if (res.errorMessage){
                    toast.error(res.errorMessage);
                  }
                }).catch((e) => {
                  logException(e, 'Error setting stop loss');
                  toast.error('Error setting stop loss');
                });
              }
            })
            .onMove(function () {
              if (!canTrade.current) {
                stopLossLine.setPrice(chartGrp.lastStoploss);
                return;
              }
              const price = stopLossLine.getPrice();
              const rounded = roundToTickSize(price, tickSize);
              stopLossLine.setPrice(rounded);

              editSltpSetting(pos.id, rounded, chartGrp.lastTakeProfit).then((res) => {
                if (res.errorMessage){
                  toast.error(res.errorMessage);

                  try {
                    stopLossLine?.setPrice(stopLoss);
                  } catch (e) {
                    console.log('Unable to update stopLoss line after error', e);
                  }
                }
              }).catch((e) => {
                logException(e, 'Error setting stop loss');
                try {
                  stopLossLine?.setPrice(stopLoss);
                } catch (e) {
                  console.log('Unable to update stopLoss line after error', e);
                }
              });
            });
        }
      }

      return stopLossLine;
    },
    [activeTradingAccount.isFollower]
  );

  const createTakeProfitLine = useCallback(
    (chartGrp: ChartInstanceData, pos: IPositionModel, tickSize: number, plotSide: chartPlotSide) => {
      let takeProfitLine = null;
      try {
        takeProfitLine = chartGrp.chart.createOrderLine({ disableUndo: true });
      } catch (e) {
        console.log('Error creating line', e);
        return;
      }

      const takeProfit = pos.takeProfit;
      const toMake = pos.toMake;
      const posSize = pos.positionSize;

      if (takeProfit && !isNaN(toMake) && toMake != null) {
        const symbolData = getContractById(pos.symbolId);

        takeProfitLine
          .setPrice(takeProfit)
          .setText('+' + toMake.toFixed(2))
          .setLineStyle(2)
          .setLineLength(plotSide == 0 ? ORDER_LEFT_PLOT_SIDE : ORDER_RIGHT_PLOT_SIDE)
          .setBodyBackgroundColor('#00ff00')
          .setBodyTextColor('#000')
          .setBodyBorderColor('#000')
          .setQuantityTextColor('#000')
          .setQuantityBorderColor('#000')
          .setQuantityBackgroundColor('#00ff00')
          .setQuantity((posSize * -1).toString())
          .setCancelButtonBorderColor('#000')
          .setCancelButtonIconColor('#000')
          .setLineColor('#00ff00');

        if (!activeTradingAccount.isFollower) {
          takeProfitLine
            .onCancel(function () {
              if (canTrade.current) {
                editSltpSetting(pos.id, chartGrp.lastStoploss, null).then((res) => {
                  if (res.errorMessage){
                    toast.error(res.errorMessage);
                  }
                }).catch((e) => {
                  logException(e, 'Error setting take profit');
                  toast.error('Error setting take profit');
                });
              }
            })
            .onMoving(function () {
              if (!canTrade.current) {
                return;
              }
              const price = takeProfitLine.getPrice();
              if (symbolData) {
                let text = '';
                switch (currentChartDisplayType.current) {
                  case ChartDisplayType.Dollar:
                    text = `$${formatPrice(calculatePnl(chartGrp.lastSize, chartGrp.lastAvgPrice, price, symbolData.tickValue / tickSize))}`;
                    break;
                  case ChartDisplayType.Percent:
                    {
                      const rounded = roundToTickSize(chartGrp.lastAvgPrice, tickSize);

                      var currentPrice = new Decimal(price).sub(rounded).mul(100).div(rounded).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
                      text = currentPrice.toFixed(2) + '%';
                    }
                    break;
                  case ChartDisplayType.Tick:
                    {
                      const rounded = roundToTickSize(chartGrp.lastAvgPrice, tickSize);
                      var ticks = new Decimal(price).sub(rounded).div(tickSize);
                      text = ticks.toFixed(0) + ' ticks';
                    }
                    break;
                }

                takeProfitLine.setText((price >= chartGrp.lastAvgPrice ? '+' : '') + text);
              }
            })
            .onMove(function () {
              if (!canTrade.current) {
                takeProfitLine.setPrice(chartGrp.lastTakeProfit);
                return;
              }
              const price = takeProfitLine.getPrice();
              const rounded = roundToTickSize(price, tickSize);
              takeProfitLine.setPrice(rounded);

              editSltpSetting(pos.id, chartGrp.lastStoploss, rounded).then((res) => {
                if (res.errorMessage){
                  toast.error(res.errorMessage);
                }
              }).catch((e) => {
                logException(e, 'Error setting take profit');
                takeProfitLine.setPrice(takeProfit);
              });
              
            });
        }
      }

      return takeProfitLine;
    },
    [activeTradingAccount.isFollower]
  );

  const createPositionLine = useCallback(
    (chartGroup: ChartGroup, chartInstance: ChartInstanceData, pos: IPositionModel, tickSize: number, plotSide: chartPlotSide) => {
      let line = null;
      try {
        line = chartInstance.chart.createOrderLine({ disableUndo: true });
      } catch (e) {
        console.log('Error creating line', e);
        return;
      }

      const symbolData = getContractById(pos.symbolId);
      const setLineRed = () => {
        line.setBodyBackgroundColor('#ff0000');
        line.setBodyTextColor('#fff');
        line.setLineColor('#ff0000');
      };
      const setLineGreen = () => {
        line.setBodyBackgroundColor('#00ff00');
        line.setBodyTextColor('#000');
        line.setLineColor('#00ff00');
      };
      if (!activeTradingAccount.isFollower) {
        line.onCancel('onClose called', function () {
          closePosition(pos.symbolId);
        });
        line.onMoving(function () {
          if (!canTrade.current) {
            return;
          }
          if (!line.isMoving) {
            line.prevText = line.getText();
          }
          line.isMoving = true;

          const price = line.getPrice();
          const targetPrice = roundToTickSize(price, tickSize);
          if (symbolData) {
            const newRisk = calculatePnl(chartInstance.lastSize, chartInstance.lastAvgPrice, targetPrice, symbolData.tickValue / tickSize);
            
            let text = '';
            switch (currentChartDisplayType.current) {
              case ChartDisplayType.Dollar:
                text = `$${formatPrice(calculatePnl(chartInstance.lastSize, chartInstance.lastAvgPrice, targetPrice, symbolData.tickValue / tickSize))}`;
                break;
              case ChartDisplayType.Percent:
                {
                  const rounded = roundToTickSize(chartInstance.lastAvgPrice, tickSize);

                  var currentPrice = new Decimal(targetPrice).sub(rounded).mul(100).div(rounded).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
                  text = currentPrice.toFixed(2) + '%';
                }
                break;
              case ChartDisplayType.Tick:
                {
                  const rounded = roundToTickSize(chartInstance.lastAvgPrice, tickSize);
                  var ticks = new Decimal(targetPrice).sub(rounded).div(tickSize);
                  text = ticks.toFixed(0) + ' ticks';
                }
                break;
            }

            if (chartInstance.lastSize > 0) {
              if (targetPrice <= chartInstance.lastAvgPrice) {
                //always set the stop lsos if it's less than our average price
                line.setText('Set Stop Loss ' + text);
                setLineRed();
              } else {
                setLineGreen();
                if (targetPrice <= chartGroup.lastPrice) {
                  //if the target price is less than our average price, but greater than last price
                  //we still set the stop loss, but on the backend, it will become a stop profit
                  line.setText('Set Stop Profit ' + text);
                } else {
                  //if the target price is greater than our average price, and also greater than the last price, we set the take profit
                  line.setText('Set Take Profit ' + text);
                }
              }
            } else {
              //always set the stop loss if the price we are dragging to is above the average position price
              if (targetPrice >= chartGroup.lastPrice) {
                setLineRed();
                line.setText('Set Stop Loss ' + text);
              } else {
                setLineGreen();
                if (targetPrice >= chartGroup.lastPrice) {
                  //if the target price is greater than our average price, but less than target price
                  //we still set the stop loss, but on the backend, it will become a stop profit
                  line.setText('Set Stop Profit ' + text);
                } else {
                  //if the target price is less than our average price, and also less than the last price, we set the take profit
                  line.setText('Set Take Profit ' + text);
                }
              }
            }
          }
        });
        line.onMove(function () {
          if (!canTrade.current) {
            line.setPrice(chartInstance.lastAvgPrice);
            return;
          }

          line.isMoving = false;
          line.setText(line.prevText);

          const price = line.getPrice();
          const targetPrice = roundToTickSize(price, tickSize);
          console.log('Line moved to price.', { ...chartInstance }, targetPrice);
          line.setPrice(chartInstance.lastAvgPrice);
          chartInstance.lastPnl = null;
          updatePositionLineText(chartInstance, line, chartGroup.lastPriceForPnl, line, currentChartDisplayType.current);
          if (chartInstance.lastSize > 0) {
            if (targetPrice <= chartInstance.lastAvgPrice) {
              //always set the stop loss if it's less than our average price
              
              editSltpSetting(pos.id, targetPrice, chartInstance.lastTakeProfit).then((res) => {
                if (res.errorMessage){
                  toast.error(res.errorMessage);
                }
              }).catch((e) => {
                logException(e, 'Error setting stop loss');
              });
              
            } else {
              if (targetPrice <= chartGroup.lastPrice) {
                //if the target price is less than our average price, but greater than last price
                //we still set the stop loss, but on the backend, it will become a stop profit

                editSltpSetting(pos.id, targetPrice, chartInstance.lastTakeProfit).then((res) => {
                  if (res.errorMessage){
                    toast.error(res.errorMessage);
                  }
                }).catch((e) => {
                  logException(e, 'Error setting stop loss');
                });
                
              } else {
                //if the target price is greater than our average price, and also greater than the last price, we set the take profit
                
                editSltpSetting(pos.id, chartInstance.lastStoploss, targetPrice).then((res) => {
                  if (res.errorMessage){
                    toast.error(res.errorMessage);
                  }
                }).catch((e) => {
                  logException(e, 'Error setting stop loss');
                });
                
              }
            }
          } else {
            //always set the stop loss if the price we are dragging to is above the average position price
            if (targetPrice >= chartGroup.lastPrice) {
              editSltpSetting(pos.id, targetPrice, chartInstance.lastTakeProfit).then((res) => {
                if (res.errorMessage){
                  toast.error(res.errorMessage);
                }
              }).catch((e) => {
                logException(e, 'Error setting stop loss');
              });
              
            } else {
              if (targetPrice >= chartGroup.lastPrice) {
                //if the target price is greater than our average price, but less than target price
                //we still set the stop loss, but on the backend, it will become a stop profit
                
                editSltpSetting(pos.id, targetPrice, chartInstance.lastTakeProfit).then((res) => {
                  if (res.errorMessage){
                    toast.error(res.errorMessage);
                  }
                }).catch((e) => {
                  logException(e, 'Error setting stop loss');
                });
                
              } else {
                //if the target price is less than our average price, and also less than the last price, we set the take profit

                editSltpSetting(pos.id, chartInstance.lastStoploss, targetPrice).then((res) => {
                  if (res.errorMessage){
                    toast.error(res.errorMessage);
                  }
                }).catch((e) => {
                  logException(e, 'Error setting stop loss');
                });
              }
            }
          }
        });
      }

      line.setBodyBorderColor('#000');
      line.setQuantityBackgroundColor(pos.positionSize > 0 ? '#00ff00' : '#ff0000');
      line.setQuantityTextColor('#000');
      line.setQuantityBorderColor('#000');
      line.setCancelButtonBorderColor('#000');
      line.setCancelButtonIconColor('#000');
      line.setCancelTooltip('Close position');
      line.setLineLength(plotSide == 0 ? LEFT_PLOT_SIDE : RIGHT_PLOT_SIDE);

      if (pos.profitAndLoss >= 0) {
        line.setBodyBackgroundColor('#00ff00');
        line.setBodyTextColor('#000');
        line.setLineColor('#00ff00');
      } else if (pos.profitAndLoss < 0) {
        line.setBodyBackgroundColor('#ff0000');
        line.setBodyTextColor('#fff');
        line.setLineColor('#ff0000');
      }
      
      line.setText(`${formatPrice(pos.profitAndLoss)} USD`);
      return line;
    },
    [activeTradingAccount.isFollower]
  );

  const updatePositionLineText = useCallback((chartGrp: ChartInstanceData, metadata: SymbolMetadata, lastPrice: number, line: IOrderLineAdapter, chartDisplayType: ChartDisplayType) => {
    try {
      if ((line as any).isMoving) return;
      if (!lastPrice) return;

      let text = '';
      switch (chartDisplayType) {
        case ChartDisplayType.Dollar:
          text = `$${formatPrice(calculatePnl(chartGrp.lastSize, chartGrp.lastAvgPrice, lastPrice, metadata.contractCost))}`;
          break;
        case ChartDisplayType.Percent:
          {
            const rounded = roundToTickSize(chartGrp.lastAvgPrice, metadata.tickSize);

            var currentPrice = new Decimal(lastPrice).sub(rounded).mul(100).div(rounded).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
            text = currentPrice.toFixed(2) + '%';
          }
          break;
        case ChartDisplayType.Tick:
          {
            const rounded = roundToTickSize(chartGrp.lastAvgPrice, metadata.tickSize);
            var ticks = new Decimal(lastPrice).sub(rounded).div(metadata.tickSize);
            text = ticks.toFixed(0) + ' ticks';
          }
          break;
      }
      var pnl = calculatePnl(chartGrp.lastSize, chartGrp.lastAvgPrice, lastPrice, metadata.contractCost);

      if (chartGrp.lastPnl != pnl) {
        if (pnl >= 0 && line.getLineColor() != '#00ff00') {
          line.setBodyBackgroundColor('#00ff00');
          line.setBodyTextColor('#000');
          line.setLineColor('#00ff00');
        } else if (pnl < 0 && line.getLineColor() != '#ff0000') {
          line.setBodyBackgroundColor('#ff0000');
          line.setBodyTextColor('#fff');
          line.setLineColor('#ff0000');
        }
        line.setText(text);
      }
    } catch (e) {
      console.warn('Error updating line', e);
    }
  }, []);

  const updatePositionLine = useCallback((chartGrp: ChartInstanceData, line: IOrderLineAdapter, pos: IPositionModel, group: ChartGroup, chartDisplayType: ChartDisplayType) => {
    try {
      if ((line as any).isMoving) return;

      if (chartGrp.lastAvgPrice != pos.averagePrice) {
        chartGrp.lastAvgPrice = pos.averagePrice;
        line.setPrice(pos.averagePrice);
      }

      if (chartGrp.lastSize != pos.positionSize) {
        chartGrp.lastSize = pos.positionSize;
        line.setQuantity(pos.positionSize.toString());
      }

      updatePositionLineText(chartGrp, group.metadata, group.lastPriceForPnl, line, chartDisplayType);
    } catch (e) {
      console.warn('Error updating line', e);
    }
  }, []);

  //creates/updates the positions
  useEffect(() => {

    if (charts.length == 0) return;
    if (customSettings.hidePositionPlots) {
      //go through and remove all of the existing lines
      for (const chartGroup of charts) {
        for (const chartInstance of chartGroup.charts) {
          if (chartInstance.positionLine) {
            try {
              chartInstance.positionLine.remove();
            } catch (e) {
              console.warn('Error removing line', e);
            }
            chartInstance.positionLine = null;
            chartInstance.positionId = 0;
            chartInstance.lastAvgPrice = null;
            chartInstance.lastPnl = null;
            chartInstance.lastSize = null;
          }

          if (chartInstance.stopLossLine) {
            try {
              chartInstance.stopLossLine.remove();
            } catch (e) {
              console.warn('Error removing line', e);
            }
            chartInstance.stopLossLine = null;
            chartInstance.lastStoploss = null;
            chartInstance.lastRisk = null;
          }

          if (chartInstance.takeProfitLine) {
            try {
              chartInstance.takeProfitLine.remove();
            } catch (e) {
              console.warn('Error removing line', e);
            }
            chartInstance.takeProfitLine = null;
            chartInstance.lastTakeProfit = null;
            chartInstance.lastToMake = null;
          }
        }
      }

      return;
    }
    for (const chartGroup of charts) {
      const symbol = chartGroup.actualSymbol;
      const positions = rawActivePositions.filter((x) => x.symbolId == symbol && x.positionSize != 0);

      for (const chartInstance of chartGroup.charts) {
        //if there are no positions, close/delete any existing lines
        if (positions.length == 0 || (chartInstance.positionId && positions[0].id != chartInstance.positionId)) {
          if (chartInstance.positionLine) {
            try {
              chartInstance.positionLine.remove();
            } catch (e) {
              console.warn('Error removing line', e);
            }
          }

          chartInstance.positionLine = null;

          if (chartInstance.stopLossLine) {
            try {
              chartInstance.stopLossLine.remove();
            } catch (e) {
              console.warn('Error removing line', e);
            }
          }

          chartInstance.stopLossLine = null;

          if (chartInstance.takeProfitLine) {
            try {
              chartInstance.takeProfitLine.remove();
            } catch (e) {
              console.warn('Error removing line', e);
            }
          }

          chartInstance.positionId = 0;
          chartInstance.takeProfitLine = null;
          chartInstance.lastAvgPrice = null;
          chartInstance.lastPnl = null;
          chartInstance.lastSize = null;
          chartInstance.lastStoploss = null;
          chartInstance.lastTakeProfit = null;
          chartInstance.lastRisk = null;
          chartInstance.lastToMake = null;
        }

        if (positions.length == 0) continue;

        const pos = positions[0];
        chartInstance.positionId = pos.id;

        if (!chartInstance.positionLine) {
          chartInstance.positionLine = createPositionLine(chartGroup, chartInstance, pos, chartGroup.tickSize, customSettings.positionPlotsSide);
        }

        if (chartInstance.positionLine) {
          try {
            updatePositionLine(chartInstance, chartInstance.positionLine, pos, chartGroup, currentChartDisplayType.current);
          } catch (e) {
            console.warn('Error updating position line', e);
          }
        }

        if (pos.stopLoss) {
          if (!chartInstance.stopLossLine) {
            chartInstance.stopLossLine = createStopLossLine(chartInstance, pos, chartGroup.tickSize, customSettings.positionPlotsSide);
          }

          if (chartInstance.stopLossLine) {
            if (
              chartInstance.lastStoploss != pos.stopLoss ||
              chartInstance.lastRisk != pos.risk ||
              chartInstance.lastStopLossPlotSide != customSettings.positionPlotsSide ||
              chartInstance.lastStopLossDisplayType != currentChartDisplayType.current
            ) {
              try {
                updateStopLossLine(chartGroup, chartInstance, pos, customSettings.positionPlotsSide, customSettings.chartDisplayType ?? ChartDisplayType.Dollar);
              } catch (e) {
                console.warn('Error updating stop loss line', e);
              }
              chartInstance.lastStoploss = pos.stopLoss;
              chartInstance.lastRisk = pos.risk;
              chartInstance.lastStopLossPlotSide = customSettings.positionPlotsSide;
              chartInstance.lastStopLossDisplayType = customSettings.chartDisplayType;
            }
          }
        } else {
          if (chartInstance.stopLossLine) {
            try {
              chartInstance.stopLossLine.remove();
            } catch (e) {
              console.warn('Error removing line', e);
            }
            chartInstance.lastStoploss = null;
            chartInstance.lastRisk = null;
            chartInstance.stopLossLine = null;
            chartInstance.lastStopLossDisplayType = null;
            chartInstance.lastStopLossPlotSide = null;
          }
        }

        if (pos.takeProfit) {
          if (!chartInstance.takeProfitLine) {
            chartInstance.takeProfitLine = createTakeProfitLine(chartInstance, pos, chartGroup.tickSize, customSettings.positionPlotsSide);
          }

          if (chartInstance.takeProfitLine) {
            if (
              chartInstance.lastTakeProfit != pos.takeProfit ||
              chartInstance.lastToMake != pos.toMake ||
              chartInstance.lastTakeProfitPlotSide != customSettings.positionPlotsSide ||
              chartInstance.lastTakeProfitDisplayType != customSettings.chartDisplayType
            ) {
              try {
                updateTakeProfitLine(chartGroup, chartInstance, pos, customSettings.positionPlotsSide, customSettings.chartDisplayType ?? ChartDisplayType.Dollar);
              } catch (e) {
                console.warn('Error updating take profit line', e);
              }
              chartInstance.lastTakeProfit = pos.takeProfit;
              chartInstance.lastToMake = pos.toMake;
              chartInstance.lastTakeProfitPlotSide = customSettings.positionPlotsSide;
              chartInstance.lastTakeProfitDisplayType = customSettings.chartDisplayType;
            }
          }
        } else {
          if (chartInstance.takeProfitLine) {
            try {
              chartInstance.takeProfitLine.remove();
            } catch (e) {
              console.warn('Error removing line', e);
            }
            chartInstance.lastTakeProfit = null;
            chartInstance.lastToMake = null;
            chartInstance.takeProfitLine = null;
            chartInstance.lastTakeProfitDisplayType = null;
            chartInstance.lastTakeProfitPlotSide = null;
          }
        }
      }
    }
  }, [charts, rawActivePositions, customSettings.hidePositionPlots, customSettings.positionPlotsSide, customSettings.chartDisplayType, createStopLossLine, createTakeProfitLine, createPositionLine]);

  return <> </>;
};

export default React.memo(ChartPositions);
