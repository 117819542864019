import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Loading from '../components/Loading';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { DomRealTime } from '../models/domRealTime';
import { QuoteData } from '../models/quoteData';
import { LinkedOrderModel, NotificationModel, OrderModel, PersonalDailyAction, PositionModel, TradeModel, TradingAccountStatus } from '../api/userApi';
import config from '../config';
import { MarketStatus, TradeLogData } from '../api/dataApi';
import dayjs from 'dayjs';
import TiltData from '../models/tiltData';
import { useAuth } from './AuthContext';
import { RtcUpdate } from '@models/rtcUpdate';
import { SessionData } from 'src/data/sessionData';
import { useSettings } from '@contexts/SettingsContext';
import { networkSpeed } from 'src/data/enumTypeMaps';
import { logException } from '@/helpers/exceptionHelper';
import { toast } from 'react-toastify';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { TradingEnvironment } from '@/data/tradingEnvironment';
import trade from '@/views/resources/components/trade/trade';

export type CqgCallbackId = number;
export enum SocketEvent {
  Quotes = 'RealTimeQuote',
  Tilt = 'RealTimeTilt'
}
const SubscribeFunctions = {
  [SocketEvent.Quotes]: 'SubscribeQuotes',
  [SocketEvent.Tilt]: 'SubscribeTilt'
};

const UnsubscribeFunction = {
  [SocketEvent.Quotes]: 'UnsubscribeQuotes',
  [SocketEvent.Tilt]: 'UnsubscribeTilt'
};
interface ICqgCallback {
  callback: (data: any) => void;
  id: CqgCallbackId;
}
export interface ICqgContext {
  on: <TKey extends SocketEvent, TData extends Pick<SocketEvents, TKey>>(type: TKey, callback: (data: TData[TKey]) => void) => void;
  off: <TKey extends SocketEvent, TData extends Pick<SocketEvents, TKey>>(type: TKey, callback: (data: TData[TKey]) => void) => void;
  subscribeBars(symbolId: number, resolution: string, guid: string, callback: (data: any) => void): number;
  unsubscribeBars(guid: string, callbackId: number);
  subscribeQuotesForSymbol: (symbol: string, callback: (data: QuoteData) => void) => number;
  unsubscribeQuotesForSymbol: (symbol: string, callbackId: number) => void;
  subscribeDom: (cqgSymbolName: string, callback: (data: DomRealTime[]) => void) => number;
  unsubscribeDom: (cqgSymbolName: string, callbackId: number) => void;
  subscribePositions(account: number, callback: (data: RtcUpdate<PositionModel>) => void): number;
  unsubscribePositions(account: number, callbackId: number): void;
  subscribeOrders(account: number, callback: (data: RtcUpdate<OrderModel>) => void): number;
  unsubscribeOrders(account: number, callbackId: number): void;
  subscribeTrades(account: number, callback: (data: RtcUpdate<TradeModel>) => void): number;
  unsubscribeTrades(account: number, callbackId: number): void;
  subscribeTradeLog: (cqgSymbolName: string, callback: (data: TradeLogData[]) => void) => number;
  unsubscribeTradeLog: (cqgSymbolName: string, callbackId: number) => void;
  subscribeMarketStatus: (callback: (data: MarketStatus) => void) => number;
  unsubscribeMarketStatus: (callbackId: number) => void;
  subscribeAccounts(userId: number, callback: (data: RtcUpdate<TradingAccountUpdateModel>) => void): void;
  unsubscribeAccounts(userId: number, callback: (data: RtcUpdate<TradingAccountUpdateModel>) => void): void;
  subscribeTradeFollows(callback: (data: RtcUpdate<TradeFollower>) => void): void;
  unsubscribeTradeFollows(callback: (data: RtcUpdate<TradeFollower>) => void): void;
  subscribeSessions(userId: number, callback: (data: RtcUpdate<SessionData>) => void): void;
  unsubscribeSessions(userId: number, callback: (data: RtcUpdate<SessionData>) => void): void;
  subscribeNotifications(callback: (data: RtcUpdate<NotificationModel>) => void): void;
  unsubscribeNotifications(callback: (data: RtcUpdate<NotificationModel>) => void): void;
  subscribeOnUserReconnect: (callback: () => void) => void;
  unsubscribeOnUserReconnect: (callback: () => void) => void;
  getServerTime: () => Promise<Date>;
  getChartsServerTime: () => Promise<Date>;
  subscribeOnChartReconnect: (callback: () => void) => void;
  unsubscribeOnChartReconnect: (callback: () => void) => void;
  subscribeLinkedOrders(account: number, callback: (data: RtcUpdate<LinkedOrderModel>) => void): number;
  unsubscribeLinkedOrders(account: number, callbackId: number): void;
  setTradingEnvironment: (env: TradingEnvironment) => void;
}
interface DomSubcription {
  cqgSymbolName: string;
  callbackCounter: number;
  callbacks: Map<number, (doms: DomRealTime[]) => void>;
}

interface QuoteSubcription {
  cqgSymbolName: string;
  callbackCounter: number;
  callbacks: Map<number, (doms: QuoteData) => void>;
}

interface TradeLogSubscription {
  cqgSymbolName: string;
  callbackCounter: number;
  callbacks: Map<number, (data: TradeLogData[]) => void>;
}
interface AccountSubscription<T> {
  account: number;
  callbackCounter: number;
  callbacks: Map<number, T>;
}
interface BarSubscription {
  symbolId: number;
  resolution: string;
  // barUnit: number;
  // unitNumber: number;
  guid: string;
  callbackCounter: number;
  callbacks: Map<number, (data: any) => void>;
}
export interface TradeFollower {
  tradingAccountId: number;
  leaderId: number;
}
export interface TradingAccountUpdateModel {
  id: number;
  balance: number;
  status: TradingAccountStatus;
  userId: number;
  nickname: string | null;
  realizedPnL: number;
  winPercentage: number;
  dailyLossLimit: number;
  personalDailyLossLimit: number | null;
  personalDailyLossLimitAction: PersonalDailyAction;
  personalDailyProfitTarget: number | null;
  personalDailyProfitTargetAction: PersonalDailyAction;
  updatedAt: string;
  totalTrades: number;
  dailyTrades: number;
}

interface ISubscriptionEntry<T, T2> {
  on: (handler: (data: T) => void) => void;
  off: (handler: (data: T) => void) => void;
  data: T2;
}

class SubscriptionEntry<T, T2> implements ISubscriptionEntry<T, T2> {
  private handlers: Array<(data: T) => void> = [];
  public data: T2;

  public get count() {
    return this.handlers.length;
  }

  on = (handler: (data: T) => void) => {
    this.handlers.push(handler);
  };

  off = (handler: (data: T) => void) => {
    this.handlers = this.handlers.filter((h) => h !== handler);
  };
  emit = (data: T) => {
    this.handlers.slice(0).forEach((h) => h(data));
  };
}

const ChartSocketEvents = [SocketEvent.Quotes, SocketEvent.Tilt];
const UserSocketEvents = [];

interface SocketEvents extends Pick<SocketEvents, any> {
  [SocketEvent.Quotes]: QuoteData[];
  [SocketEvent.Tilt]: TiltData[];
}

//add params
const logCqgException = (err: any, message: string, ...optionalParams: any[]) => {
  console.error(message, err, ...optionalParams);
}

export const CqgContext = React.createContext<ICqgContext>({} as any);
export const useCqg = () => React.useContext<ICqgContext>(CqgContext);

function CqgProvider({ children }: any) {
  const barSubscriptionRef = useRef(new Map<string, BarSubscription>());
  const domSubscriptionRef = useRef(new Map<string, DomSubcription>());
  const quoteSubscriptions = useRef(new Map<string, QuoteSubcription>());

  const marketStatusSubscriptions = useRef(new Map<number, (data: MarketStatus) => void>());
  const chartEvents = useRef<Map<SocketEvent, SubscriptionEntry<any, number>>>(new Map());

  const accountEventsRef = useRef<SubscriptionEntry<RtcUpdate<TradingAccountUpdateModel>, number>>(new SubscriptionEntry());
  const tradeFollowerEventsRef = useRef<SubscriptionEntry<RtcUpdate<TradeFollower>, number>>(new SubscriptionEntry());
  const sessionEventsRef = useRef<SubscriptionEntry<RtcUpdate<SessionData>, number>>(new SubscriptionEntry());
  const notificationEventsRef = useRef<SubscriptionEntry<RtcUpdate<NotificationModel>, number>>(new SubscriptionEntry());
  const positionSubscriptionsRef = useRef(new Map<number, AccountSubscription<(data: RtcUpdate<PositionModel>) => void>>());
  const orderSubscriptionsRef = useRef(new Map<number, AccountSubscription<(data: RtcUpdate<OrderModel>) => void>>());

  const tradeSubscriptionsRef = useRef(new Map<number, AccountSubscription<(data: RtcUpdate<TradeModel>) => void>>());
  const linkedOrderSubscriptionsRef = useRef(new Map<number, AccountSubscription<(data: RtcUpdate<LinkedOrderModel>) => void>>());
  const tradeLogSubscriptions = useRef(new Map<string, TradeLogSubscription>());

  const idRef = useRef(1);
  const { isMobile } = useDeviceContext();
  const chartRtc = useRef<signalR.HubConnection>(null);
  const userRtc = useRef<signalR.HubConnection>(null);
  const { token, loggedIn, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const onUserApiReconnected = useRef<Array<() => void>>([]);
  const onChartApiReconnected = useRef<Array<() => void>>([]);
  const deviceContext = useDeviceContext();
  const tokenRef = useRef<string>(token);
  const settings = useSettings();
  const networkSpeedRef = useRef<networkSpeed>(settings.customSettings?.networkSpeedNew === undefined ? networkSpeed.Medium : settings.customSettings.networkSpeedNew);
  const [tradingEnvironment, setTradingEnvironment] = useState<TradingEnvironment>(TradingEnvironment.None);
  const isMounted = useRef(true);

  useEffect(() => {
    tokenRef.current = token;
  }, [token]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (tradingEnvironment == TradingEnvironment.None) return;

    const callback = () => {
      if (userRtc.current?.state == HubConnectionState.Connected) {
        userRtc.current.invoke('RegisterSession', sessionId).catch((err) => {});
      }
    };

    let interval = null;

    let timeout = setTimeout(() => {
      callback();
      timeout = null;
      interval = setInterval(callback, 30000);
    }, 1000);

    return () => {
      if (userRtc.current?.state == HubConnectionState.Connected) {
        userRtc.current.invoke('DeregisterSession', sessionId).catch((err) => {});
      }
      if (interval) clearInterval(interval);
      if (timeout) clearTimeout(timeout);
    };
  }, [sessionId, tradingEnvironment]);

  useEffect(() => {
    networkSpeedRef.current = settings.customSettings?.networkSpeedNew === undefined ? networkSpeed.Medium : settings.customSettings.networkSpeedNew;
    console.log('Network speed changed', networkSpeedRef.current);
    if (chartRtc.current?.state == HubConnectionState.Connected) {
      domSubscriptionRef.current.forEach((sub) => {
        if (sub.callbacks.size > 0) {
          chartRtc.current.invoke('UnsubscribeDom', sub.cqgSymbolName).catch((err) => {
            logCqgException(err, 'Error on reconnect - UnsubscribeDom');
          });
          chartRtc.current.invoke('SubscribeDomWithSpeed', sub.cqgSymbolName, networkSpeedRef.current).catch((err) => {
            logCqgException(err, 'Error on reconnect - SubscribeDomWithSpeed');
          });
        }
      });

      if (quoteSubscriptions.current.size > 0) {
        chartRtc.current.invoke('UnsubscribeQuotes').catch((err) => {
          logCqgException(err, 'Error on reconnect - UnsubscribeQuotes');
        });
        chartRtc.current.invoke('SubscribeQuotesWithSpeed', networkSpeedRef.current).catch((err) => {
          logCqgException(err, `Error on reconnect - SubscribeQuotesWithSpeed @${networkSpeedRef.current}`);
        });
      }
    }
  }, [settings.customSettings?.networkSpeedNew]);

  useEffect(() => {
    if (!loggedIn) return;

    const userApiUrl = `${config.userApi}/hubs/account`;
    const chartApiUrl = `${config.chartApi}/hubs/chart`;
    const userConn = new HubConnectionBuilder()
      .withUrl(userApiUrl, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => tokenRef.current,
        timeout: 10000
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.previousRetryCount < 3) {
            return 200;
          } else {
            if (retryContext.elapsedMilliseconds < 10000) {
              return 1500;
            } else if (retryContext.elapsedMilliseconds < 30000) {
              return 3000;
            } else {
              return 5000;
            }
          }
        }
      })
      .build();

    const chartConn = new HubConnectionBuilder()
      .withUrl(chartApiUrl, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => tokenRef.current,
        timeout: 5000
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.previousRetryCount < 3) {
            return 200;
          } else {
            if (retryContext.elapsedMilliseconds < 10000) {
              return 1500;
            } else if (retryContext.elapsedMilliseconds < 30000) {
              return 3000;
            } else {
              return 5000;
            }
          }
        }
      })
      .build();

    chartConn.onreconnected((connectionId) => {
      if (!isMounted.current) return;
      console.log('Reconnected Chart Hub');
      toast.dismiss('chartApiDisconnected');
      toast('Chart API Reconnected', {
        toastId: 'chartApiReconnected',
        icon: '✅',
        autoClose: 3000,
        pauseOnFocusLoss: false,
        hideProgressBar: true,
        type: 'success'
      });

      resubscribeChartRtc(chartConn);
    });

    chartConn.onclose((error) => {
      if (!isMounted.current) return;
      console.log('Chart API Disconnected', error);
      toast.dismiss('chartApiReconnected');

      if (!isMobile) {
        // Will spam mobile when changing pages
        toast('Chart API disconnected', {
          icon: '⚠️',
          autoClose: 5000,
          type: 'error',
          pauseOnFocusLoss: false,
          hideProgressBar: true,
          toastId: 'chartApiDisconnected'
        });
      }
    });

    chartConn.onreconnecting((error) => {
      if (!isMounted.current) return;
      console.log('Chart API Reconnecting', error);
      toast.dismiss('chartApiReconnected');

      if (!isMobile) {
        // Will spam mobile when changing pages
        toast('Chart API reconnecting', {
          icon: '⚠️',
          autoClose: 5000,
          type: 'error',
          pauseOnFocusLoss: false,
          hideProgressBar: true,
          toastId: 'chartApiDisconnected'
        });
      }
    });

    userConn.onreconnected((connectionId) => {
      if (!isMounted.current) return;
      console.log('Reconnected User Hub');
      toast.dismiss('userApiDisconnected');
      toast.update('userApiReconnected', {
        render: 'User API Reconnected',
        icon: '✅',
        autoClose: 3000,
        pauseOnFocusLoss: false,
        hideProgressBar: true,
        type: 'success'
      });

      resubscribeUserRtc(userConn);
    });

    userConn.onclose((error) => {
      if (!isMounted.current) return;

      console.log('User API Disconnected', error);
      toast.dismiss('userApiReconnected');

      if (!isMobile) {
        // Will spam mobile when changing pages
        toast('User API reconnecting', {
          icon: '⚠️',
          autoClose: 5000,
          pauseOnFocusLoss: false,
          hideProgressBar: true,
          type: 'error',
          toastId: 'userApiDisconnected'
        });
      }
    });

    userConn.onreconnecting((error) => {
      if (!isMounted.current) return;

      console.log('User API Reconnecting', error);
      toast.dismiss('userApiReconnected');

      if (!isMobile) {
        // Will spam mobile when changing pages
        toast('User API disconnected', {
          icon: '⚠️',
          autoClose: 5000,
          pauseOnFocusLoss: false,
          hideProgressBar: true,
          type: 'error',
          toastId: 'userApiDisconnected'
        });
      }
    });

    userRtc.current = userConn;
    chartRtc.current = chartConn;

    Promise.all([userConn.start(), chartConn.start()]).then(() => {
      userConn.on('RealTimePosition', (update: RtcUpdate<PositionModel>) => {
        const subs = positionSubscriptionsRef.current.get(update.data.accountId);
        if (subs) {
          subs.callbacks.forEach((cb) => cb(update));
        }
      });

      userConn.on('RealTimeOrder', (update: RtcUpdate<OrderModel>) => {
        const subs = orderSubscriptionsRef.current.get(update.data.accountId);
        if (subs) {
          subs.callbacks.forEach((cb) => cb(update));
        }
      });

      userConn.on('RealTimeTrade', (update: RtcUpdate<TradeModel>) => {
        const subs = tradeSubscriptionsRef.current.get(update.data.accountId);
        if (subs) {
          subs.callbacks.forEach((cb) => cb(update));
        }
      });

      userConn.on('RealTimeSession', (update: RtcUpdate<SessionData>) => {
        sessionEventsRef.current.emit(update);
      });

      userConn.on('RealTimeNotification', (update: RtcUpdate<NotificationModel>) => {
        notificationEventsRef.current.emit(update);
      });

      userConn.on('RealTimeAccount', (update: RtcUpdate<TradingAccountUpdateModel>) => {
        accountEventsRef.current.emit(update);
      });

      userConn.on('RealTimeTradeFollower', (update: RtcUpdate<TradeFollower>) => {
        tradeFollowerEventsRef.current.emit(update);
      });

      userConn.on('RealTimeLinkedOrder', (update: RtcUpdate<LinkedOrderModel>) => {
        const subs = linkedOrderSubscriptionsRef.current.get(update.data.tradingAccountId);
        if (subs) {
          subs.callbacks.forEach((cb) => cb(update));
        }
      });

      chartConn.on('RealTimeBar', (symbolId, resolution, bar) => {
        const info = barSubscriptionRef.current.get(`${symbolId}_${resolution}`);
        if (info) {
          info.callbacks.forEach((cb) => cb(bar));
        }
      });

      chartConn.on('RealTimeDom', (symbolId: string, doms: DomRealTime[]) => {
        const info = domSubscriptionRef.current.get(symbolId);
        if (info) {
          info.callbacks.forEach((cb) => cb(doms));
        }
      });

      userConn.on('RealTimeMarketStatus', (status: MarketStatus) => {
        marketStatusSubscriptions.current.forEach((cb) => cb(status));
      });

      chartConn.on('RealTimeSymbolQuote', (quote: QuoteData) => {
        const sub = quoteSubscriptions.current.get(quote.symbol);
        if (sub) sub.callbacks.forEach((cb) => cb(quote));
      });

      chartConn.on('RealTimeTradeLogWithSpeed', (symbol: string, tradeLog: TradeLogData[]) => {
        const info = tradeLogSubscriptions.current.get(symbol);
        if (info) {
          for (const log of tradeLog) log.timestamp = dayjs(log.timestamp);

          info.callbacks.forEach((cb) => cb(tradeLog));
        }
      });

      setIsLoading(false);
    });

    return () => {
      chartConn
        .stop()
        .then(() => {
          console.log('Chart Connection stopped.');
        })
        .catch((err) => {
          logCqgException(err, 'Potential error when stopping chartRtc');
        });

      userConn
        .stop()
        .then(() => {
          console.log('User Connection stopped.');
        })
        .catch((err) => {
          logCqgException(err, 'Potential error when stopping userRtc');
        });
    };
  }, [loggedIn]);

  const lastUserConnected = useRef<boolean | null>(null);

  const getConnected = (value: HubConnectionState) => {
    if (value === HubConnectionState.Connected) {
      return true;
    }
    return false;
  };

  const resubscribeUserRtc = useCallback((conn: HubConnection) => {
    for (const subs of positionSubscriptionsRef.current.values()) {
      if (subs.callbacks.size > 0) {
        conn.invoke('SubscribePositions', subs.account).catch((err) => {
          logCqgException(err, `Error when resubscribing positions for account ${subs.account}`);
        });
      }
    }

    for (const subs of orderSubscriptionsRef.current.values()) {
      if (subs.callbacks.size > 0) {
        conn.invoke('SubscribeOrders', subs.account).catch((err) => {
          logCqgException(err, `Error when resubscribing orders for account ${subs.account}`);
        });
      }
    }

    for (const subs of tradeSubscriptionsRef.current.values()) {
      if (subs.callbacks.size > 0) {
        conn.invoke('SubscribeTrades', subs.account).catch((err) => {
          logCqgException(err, `Error when resubscribing trades for account ${subs.account}`);
        });
      }
    }

    for (const sub of linkedOrderSubscriptionsRef.current.values()) {
      if (sub.callbacks.size > 0) {
        conn.invoke('SubscribeLinkedOrders', sub.account).catch((err) => {
          logCqgException(err, `Error when resubscribing linked orders for account ${sub.account}`);
        });
      }
    }

    if (accountEventsRef.current.count > 0) {
      conn.invoke('SubscribeAccounts', accountEventsRef.current.data).catch((err) => {
        logCqgException(err, `Error when subscribing accounts for user ${accountEventsRef.current.data}`);
      });
    }

    if (tradeFollowerEventsRef.current.count > 0) {
      conn.invoke('SubscribeTradeFollowers').catch((err) => {
        logCqgException(err, 'Error when subscribing trade followers');
      });
    }

    if (sessionEventsRef.current.count > 0) {
      conn.invoke('SubscribeSessions', sessionEventsRef.current.data).catch((err) => {
        logCqgException(err, `Error when subscribing sessions for user ${sessionEventsRef.current.data}`);
      });
    }

    if (notificationEventsRef.current.count > 0) {
      conn.invoke('SubscribeNotifications').catch((err) => {
        logCqgException(err, `Error when subscribing notifications`);
      });
    }

    if (marketStatusSubscriptions.current.size > 0) {
      conn.invoke('SubscribeMarketStatus').catch((err) => {
        logCqgException(err, 'Error when resubscribing market status');
      });
    }

    onUserApiReconnected.current.forEach((cb) => cb());
  }, []);

  const resubscribeChartRtc = useCallback((conn: HubConnection) => {
    barSubscriptionRef.current.forEach((sub) => {
      conn.invoke('SubscribeBars', sub.symbolId, sub.resolution).catch((err) => {
        logCqgException(err, `Error when resubscribing bars for symbol ${sub.symbolId} @ ${sub.resolution}`);
      });
    });

    domSubscriptionRef.current.forEach((sub) => {
      conn.invoke('SubscribeDomWithSpeed', sub.cqgSymbolName, networkSpeedRef.current).catch((err) => {
        logCqgException(err, `Error when resubscribing dom for symbol ${sub.cqgSymbolName}`);
      });
    });

    if (quoteSubscriptions.current.size > 0) {
      quoteSubscriptions.current.forEach((cb, symbol) => {
        conn.invoke('SubscribeQuotesForSymbolWithSpeed', symbol, networkSpeedRef.current).catch((err) => {
          logCqgException(err, `Error when resubscribing quotes for symbol ${symbol}`);
        });
      });
    }

    tradeLogSubscriptions.current.forEach((sub) => {
      conn.invoke('SubscribeTradeLogWithSpeed', sub.cqgSymbolName, networkSpeedRef.current).catch((err) => {
        logCqgException(err, `Error when resubscribing trade log for symbol ${sub.cqgSymbolName} with speed ${networkSpeedRef.current}`);
      });
    });

    onChartApiReconnected.current.forEach((cb) => cb());
  }, []);

  const unsubscribeTradeLog = useCallback((cqgSymbolName: string, callbackId: number) => {
    const info = tradeLogSubscriptions.current.get(cqgSymbolName);
    if (info) {
      info.callbacks.delete(callbackId);
      if (info.callbacks.size == 0) {
        tradeLogSubscriptions.current.delete(cqgSymbolName);
        if (chartRtc.current.state == HubConnectionState.Connected) {
          chartRtc.current.invoke('UnsubscribeTradeLog', cqgSymbolName).catch((err) => {
            logCqgException(err, `Error when unsubscribing trade log for symbol ${cqgSymbolName}`);
          });
        }
      }
    }
  }, []);

  const subscribeTradeLog = useCallback((cqgSymbolName: string, callback: (data: TradeLogData[]) => void) => {
    const info = tradeLogSubscriptions.current.get(cqgSymbolName);
    let id = 0;
    if (info) {
      id = ++info.callbackCounter;
      info.callbacks.set(id, callback);
    } else {
      id = 1;

      tradeLogSubscriptions.current.set(cqgSymbolName, {
        cqgSymbolName,
        callbackCounter: 1,
        callbacks: new Map([[1, callback]])
      });

      if (chartRtc.current.state == HubConnectionState.Connected) {
        chartRtc.current.invoke('SubscribeTradeLogWithSpeed', cqgSymbolName, networkSpeedRef.current).catch((err) => {
          logCqgException(err, `Error when subscribing trade log for symbol ${cqgSymbolName} with speed ${networkSpeedRef.current}`);
        });
      }
    }
    return id;
  }, []);

  const subscribeNotifications = useCallback((callback: (data: RtcUpdate<NotificationModel>) => void) => {
    notificationEventsRef.current.on(callback);
    if (userRtc.current.state == HubConnectionState.Connected && notificationEventsRef.current.count == 1) {
      userRtc.current.invoke('SubscribeNotifications').catch((err) => {
        logCqgException(err, 'Error when subscribing notifications');
      });
    }
  }, []);

  const unsubscribeNotifications = useCallback((callback: (data: RtcUpdate<NotificationModel>) => void) => {
    notificationEventsRef.current.off(callback);
    if (userRtc.current.state == HubConnectionState.Connected && notificationEventsRef.current.count == 0) {
      userRtc.current.invoke('UnsubscribeNotifications').catch((err) => {
        logCqgException(err, 'Error when unsubscribing notifications');
      });
    }
  }, []);

  const subscribeSessions = useCallback((userId: number, callback: (data: RtcUpdate<SessionData>) => void) => {
    sessionEventsRef.current.on(callback);
    if (userRtc.current.state == HubConnectionState.Connected && sessionEventsRef.current.count == 1) {
      sessionEventsRef.current.data = userId;
      userRtc.current.invoke('SubscribeSessions', userId).catch((err) => {
        logCqgException(err, 'Error when subscribing sessions');
      });
    }
  }, []);

  const unsubscribeSessions = useCallback((userId: number, callback: (data: RtcUpdate<SessionData>) => void) => {
    sessionEventsRef.current.off(callback);
    if (userRtc.current.state == HubConnectionState.Connected && sessionEventsRef.current.count == 0) {
      userRtc.current.invoke('UnsubscribeSessions', userId).catch((err) => {
        logCqgException(err, 'Error when unsubscribing sessions');
      });
    }
  }, []);

  const subscribeTradeFollows = useCallback((callback: (data: RtcUpdate<TradeFollower>) => void) => {
    tradeFollowerEventsRef.current.on(callback);
    if (userRtc.current.state == HubConnectionState.Connected && tradeFollowerEventsRef.current.count == 1) {
      userRtc.current.invoke('SubscribeTradeFollowers').catch((err) => {
        logCqgException(err, 'Error when subscribing trade followers');
      });
    }
  }, []);

  const unsubscribeTradeFollows = useCallback((callback: (data: RtcUpdate<TradeFollower>) => void) => {
    tradeFollowerEventsRef.current.off(callback);
    if (userRtc.current.state == HubConnectionState.Connected && tradeFollowerEventsRef.current.count == 0) {
      userRtc.current.invoke('UnsubscribeTradeFollowers').catch((err) => {
        logCqgException(err, 'Error when unsubscribing trade followers');
      });
    }
  }, []);

  const subscribeAccounts = useCallback((userId: number, callback: (data: RtcUpdate<TradingAccountUpdateModel>) => void) => {
    accountEventsRef.current.on(callback);
    if (userRtc.current.state == HubConnectionState.Connected && accountEventsRef.current.count == 1) {
      accountEventsRef.current.data = userId;
      userRtc.current.invoke('SubscribeAccounts', userId).catch((err) => {
        logCqgException(err, `Error when subscribing accounts for user ${userId}`);
      });
    }
  }, []);

  const unsubscribeAccounts = useCallback((userId: number, callback: (data: RtcUpdate<TradingAccountUpdateModel>) => void) => {
    accountEventsRef.current.off(callback);
    if (userRtc.current.state == HubConnectionState.Connected && accountEventsRef.current.count == 0) {
      userRtc.current.invoke('UnsubscribeAccounts', userId).catch((err) => {
        logCqgException(err, `Error when unsubscribing accounts for user ${userId}`);
      });
    }
  }, []);

  const subscribeOnChartReconnect = useCallback((callback: () => void) => {
    onChartApiReconnected.current.push(callback);
  }, []);

  const unsubscribeOnChartReconnect = useCallback((callback: () => void) => {
    onChartApiReconnected.current = onChartApiReconnected.current.filter((x) => x !== callback);
  }, []);

  const subscribeOnUserReconnect = useCallback((callback: () => void) => {
    onUserApiReconnected.current.push(callback);
  }, []);

  const unsubscribeOnUserReconnect = useCallback((callback: () => void) => {
    onUserApiReconnected.current = onUserApiReconnected.current.filter((x) => x !== callback);
  }, []);

  const subscribeQuotesForSymbol = useCallback((symbol: string, callback: (data: QuoteData) => void) => {
    if (!quoteSubscriptions.current.has(symbol)) {
      quoteSubscriptions.current.set(symbol, {
        cqgSymbolName: symbol,
        callbackCounter: 0,
        callbacks: new Map()
      });
    }

    const info = quoteSubscriptions.current.get(symbol);
    const callbackId = info.callbackCounter++;
    info.callbacks.set(callbackId, callback);

    if (chartRtc.current.state == HubConnectionState.Connected) {
      if (info.callbacks.size == 1) {
        chartRtc.current.invoke('SubscribeQuotesForSymbolWithSpeed', symbol, networkSpeedRef.current).catch((err) => {
          logCqgException(err, `Error when subscribing quotes for symbol ${symbol}`);
        });
      } else {
        chartRtc.current.invoke('GetFullQuote', symbol).catch((err) => {
          logCqgException(err, `Error when getting full quote for symbol ${symbol}`);
        });
      }
    }

    return callbackId;
  }, []);

  const unsubscribeQuotesForSymbol = useCallback((cqgName: string, callbackId: number) => {
    const info = quoteSubscriptions.current.get(cqgName);
    if (!info) return;

    info.callbacks.delete(callbackId);
    if (chartRtc.current?.state == HubConnectionState.Connected && info.callbacks.size == 0) {
      chartRtc.current.invoke('UnsubscribeQuote', cqgName).catch((err) => {
        logCqgException(err, `Error when unsubscribing quotes for symbol ${cqgName}`);
      });
    }
  }, []);

  const unsubscribePositions = useCallback((accountId: number, callbackId: number) => {
    const info = positionSubscriptionsRef.current.get(accountId);
    if (info) {
      info.callbacks.delete(callbackId);
      if (info.callbacks.size == 0) {
        positionSubscriptionsRef.current.delete(accountId);
        if (userRtc.current.state == HubConnectionState.Connected) {
          userRtc.current.invoke('UnsubscribePositions', accountId).catch((err) => {
            logCqgException(err, `Error when unsubscribing positions for account ${accountId}`);
          });
        }
      }
    }
  }, []);

  const subscribePositions = useCallback((accountId: number, callback: (position: RtcUpdate<PositionModel>) => void) => {
    if (!positionSubscriptionsRef.current.has(accountId)) {
      positionSubscriptionsRef.current.set(accountId, {
        account: accountId,
        callbackCounter: 0,
        callbacks: new Map()
      });
    }

    const info = positionSubscriptionsRef.current.get(accountId);
    const callbackId = info.callbackCounter++;
    info.callbacks.set(callbackId, callback);
    if (info.callbacks.size == 1 && userRtc.current.state == HubConnectionState.Connected) {
      userRtc.current.invoke('SubscribePositions', accountId).catch((err) => {
        logCqgException(err, `Error when subscribing positions for account ${accountId}`);
      });
    }
    return callbackId;
  }, []);

  const subscribeMarketStatus = useCallback((callback: (data: MarketStatus) => void) => {
    const callbackId = idRef.current++;
    marketStatusSubscriptions.current.set(callbackId, callback);
    if (marketStatusSubscriptions.current.size == 1 && chartRtc.current.state == HubConnectionState.Connected) {
      userRtc.current.invoke('SubscribeMarketStatus').catch((err) => {
        logCqgException(err, 'Error when subscribing market status');
      });
    }
    return callbackId;
  }, []);

  const unsubscribeMarketStatus = useCallback((callbackId: number) => {
    marketStatusSubscriptions.current.delete(callbackId);
    if (marketStatusSubscriptions.current.size == 0 && chartRtc.current.state == HubConnectionState.Connected) {
      userRtc.current.invoke('UnsubscribeMarketStatus').catch((err) => {
        logCqgException(err, 'Error when unsubscribing market status');
      });
    }
  }, []);

  const subscribeOrders = useCallback((accountId: number, callback: (order: RtcUpdate<OrderModel>) => void) => {
    if (!orderSubscriptionsRef.current.has(accountId)) {
      orderSubscriptionsRef.current.set(accountId, {
        account: accountId,
        callbackCounter: 0,
        callbacks: new Map()
      });
    }

    const info = orderSubscriptionsRef.current.get(accountId);
    const callbackId = info.callbackCounter++;
    info.callbacks.set(callbackId, callback);
    if (info.callbacks.size == 1 && userRtc.current.state == HubConnectionState.Connected) {
      userRtc.current.invoke('SubscribeOrders', accountId).catch((err) => {
        logCqgException(err, 'Error when subscribing orders');
      });
    }
    return callbackId;
  }, []);

  //generate unsubcribeOrders
  const unsubscribeOrders = useCallback((accountId: number, callbackId: number) => {
    const info = orderSubscriptionsRef.current.get(accountId);
    if (info) {
      info.callbacks.delete(callbackId);
      if (info.callbacks.size == 0) {
        orderSubscriptionsRef.current.delete(accountId);
        if (userRtc.current.state == HubConnectionState.Connected) {
          userRtc.current.invoke('UnsubscribeOrders', accountId).catch((err) => {
            logCqgException(err, `Error when unsubscribing orders for account ${accountId}`);
          });
        }
      }
    }
  }, []);

  const subscribeLinkedOrders = useCallback((accountId: number, callback: (order: RtcUpdate<LinkedOrderModel>) => void) => {
    if (!linkedOrderSubscriptionsRef.current.has(accountId)) {
      linkedOrderSubscriptionsRef.current.set(accountId, {
        account: accountId,
        callbackCounter: 0,
        callbacks: new Map()
      });
    }

    const info = linkedOrderSubscriptionsRef.current.get(accountId);
    const callbackId = info.callbackCounter++;
    info.callbacks.set(callbackId, callback);
    if (info.callbacks.size == 1 && userRtc.current.state == HubConnectionState.Connected) {
      userRtc.current.invoke('SubscribeLinkedOrders', accountId).catch((err) => {
        logCqgException(err, `Error when subscribing linked orders for account ${accountId}`);
      });
    }
    return callbackId;
  }, []);

  const unsubscribeLinkedOrders = useCallback((accountId: number, callbackId: number) => {
    const info = linkedOrderSubscriptionsRef.current.get(accountId);
    if (info) {
      info.callbacks.delete(callbackId);
      if (info.callbacks.size == 0) {
        linkedOrderSubscriptionsRef.current.delete(accountId);
        if (userRtc.current.state == HubConnectionState.Connected) {
          userRtc.current.invoke('UnsubscribeLinkedOrders', accountId).catch((err) => {
            logCqgException(err, `Error when unsubscribing linked orders for account ${accountId}`);
          });
        }
      }
    }
  }, []);

  const subscribeTrades = useCallback((accountId: number, callback: (order: RtcUpdate<TradeModel>) => void) => {
    if (!tradeSubscriptionsRef.current.has(accountId)) {
      tradeSubscriptionsRef.current.set(accountId, {
        account: accountId,
        callbackCounter: 0,
        callbacks: new Map()
      });
    }

    const info = tradeSubscriptionsRef.current.get(accountId);
    const callbackId = info.callbackCounter++;
    info.callbacks.set(callbackId, callback);
    if (info.callbacks.size == 1 && userRtc.current.state == HubConnectionState.Connected) {
      userRtc.current.invoke('SubscribeTrades', accountId).catch((err) => {
        logCqgException(err, `Error when subscribing trades for account ${accountId}`);
      });
    }
    return callbackId;
  }, []);

  const unsubscribeTrades = useCallback((accountId: number, callbackId: number) => {
    const info = tradeSubscriptionsRef.current.get(accountId);
    if (info) {
      info.callbacks.delete(callbackId);
      if (info.callbacks.size == 0) {
        tradeSubscriptionsRef.current.delete(accountId);
        if (userRtc.current.state == HubConnectionState.Connected) {
          userRtc.current.invoke('UnsubscribeTrades', accountId).catch((err) => {
            logCqgException(err, `Error when unsubscribing trades for account ${accountId}`);
          });
        }
      }
    }
  }, []);

  const subscribeDom = useCallback((cqgName: string, callback: (doms: DomRealTime[]) => void) => {
    if (!domSubscriptionRef.current.has(cqgName)) {
      domSubscriptionRef.current.set(cqgName, {
        cqgSymbolName: cqgName,
        callbackCounter: 0,
        callbacks: new Map()
      });
    }

    const info = domSubscriptionRef.current.get(cqgName);
    const callbackId = info.callbackCounter++;
    info.callbacks.set(callbackId, callback);
    if (chartRtc.current.state == HubConnectionState.Connected) {
      if (info.callbacks.size == 1) {
        chartRtc.current.invoke('SubscribeDomWithSpeed', cqgName, networkSpeedRef.current).catch((err) => {
          logCqgException(err, `Error when subscribing dom for symbol ${cqgName} @ speed ${networkSpeedRef.current}`);
        });
      } else {
        chartRtc.current.invoke('GetDomData', cqgName).catch((err) => {
          logCqgException(err, `Error when getting dom data for symbol ${cqgName}`);
        });
      }
    }

    return callbackId;
  }, []);

  const unsubscribeDom = useCallback((cqgName: string, callbackId: number) => {
    const info = domSubscriptionRef.current.get(cqgName);
    if (!info) return;

    info.callbacks.delete(callbackId);
    if (chartRtc.current?.state == HubConnectionState.Connected && info.callbacks.size == 0) {
      chartRtc.current.invoke('UnsubscribeDom', cqgName).catch((err) => {
        logCqgException(err, `Error when unsubscribing dom for symbol ${cqgName}`);
      });
    }
  }, []);

  const unsubscribeBars = useCallback((guid: string, callbackId: number) => {
    for (const [key, value] of barSubscriptionRef.current.entries()) {
      if (value.guid == guid) {
        value.callbacks.delete(callbackId);
        if (chartRtc.current.state == HubConnectionState.Connected && value.callbacks.size == 0) {
          chartRtc.current.invoke('UnsubscribeBars', value.symbolId, value.resolution).catch((err) => {
            logCqgException(err, `Error when unsubscribing bars for symbol ${value.symbolId} @ ${value.resolution}`);
          });
          barSubscriptionRef.current.delete(key);
        }
      }
    }
  }, []);

  const subscribeBars = useCallback((symbolId: number, resolution: string, guid: string, callback: (data: any) => void) => {
   
    //const callbackId = on(CqgEvent.Bars, callback);
    const key = `${symbolId}_${resolution}`;
    if (!barSubscriptionRef.current.has(key)) {
      barSubscriptionRef.current.set(key, {
        symbolId,
        resolution,
        guid,
        callbackCounter: 0,
        callbacks: new Map()
      });
    }

    const info = barSubscriptionRef.current.get(key);

    const callbackId = info.callbackCounter++;
    info.callbacks.set(callbackId, callback);

    if (chartRtc.current.state == HubConnectionState.Connected && info.callbacks.size == 1) {
      chartRtc.current.invoke('SubscribeBars', symbolId, resolution).catch((err) => {
        logCqgException(err, `Error when subscribing bars for symbol ${symbolId} @ ${resolution}`);
      });
    }

    return callbackId;
  }, []);

  const getServerTime = () => {
    return userRtc.current.invoke<Date>('ServerTime');
  };

  const getChartsServerTime = () => {
    return chartRtc.current.invoke<Date>('ServerTime');
  };

  const on = <TKey extends SocketEvent, TData extends Pick<SocketEvents, TKey>>(type: TKey, callback: (data: TData[TKey]) => void): void => {
    if (ChartSocketEvents.includes(type)) {
      let entry: SubscriptionEntry<TData[TKey], number> = null;

      if (!chartEvents.current.has(type)) {
        entry = new SubscriptionEntry<TData[TKey], number>();
        chartEvents.current.set(type, entry);
        chartRtc.current.on(type, entry.emit);
        chartRtc.current.send(SubscribeFunctions[type]);
      } else {
        entry = chartEvents.current.get(type);
      }

      entry.on(callback);
    }
  };

  const off = <TKey extends SocketEvent, TData extends Pick<SocketEvents, TKey>>(type: TKey, callback: (data: TData[TKey]) => void): void => {
    if (ChartSocketEvents.includes(type)) {
      if (chartEvents.current.has(type)) {
        const entry = chartEvents.current.get(type);
        entry.off(callback);

        if (entry.count == 0) {
          chartEvents.current.delete(type);
          chartRtc.current.send(UnsubscribeFunction[type]);
        }
      }
    }
  };

  const values = useMemo<ICqgContext>(() => {
    const ctx: ICqgContext = {
      subscribeBars,
      unsubscribeBars,
      subscribeDom,
      unsubscribeDom,
      subscribePositions,
      unsubscribePositions,
      subscribeTradeLog,
      unsubscribeTradeLog,
      subscribeOrders,
      unsubscribeOrders,
      on,
      off,
      subscribeAccounts,
      unsubscribeAccounts,
      subscribeSessions,
      unsubscribeSessions,
      subscribeTrades,
      unsubscribeTrades,
      subscribeMarketStatus,
      unsubscribeMarketStatus,
      subscribeNotifications,
      unsubscribeNotifications,
      subscribeOnUserReconnect,
      unsubscribeOnUserReconnect,
      getServerTime,
      getChartsServerTime,
      subscribeQuotesForSymbol,
      unsubscribeQuotesForSymbol,
      subscribeOnChartReconnect,
      unsubscribeOnChartReconnect,
      subscribeTradeFollows,
      unsubscribeTradeFollows,
      subscribeLinkedOrders,
      unsubscribeLinkedOrders,
      setTradingEnvironment
    };
    return ctx;
  }, []);
  return (
    <CqgContext.Provider value={values}>
      {isLoading && <Loading />}
      {!isLoading && children}
    </CqgContext.Provider>
  );
}

export default CqgProvider;
