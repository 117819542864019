import React, { useCallback, useMemo } from 'react';
import { OrderPromptType, useOrders } from '../../../../contexts/OrdersContext';
import styles from './dom.module.scss';
import { useSymbol } from '@contexts/SymbolContext';
import { useDom } from '@contexts/DomContext';
import classNames from 'classnames';
import BidCell from './bidCell';
import AskCell from './askCell';
import PnlCell from './pnlCell';
import VolumeCell from './volumeCell';
import OrderCell from './orderCell';
import { formatContractPrice, formatPriceNoGrouping, getPriceDigitFormatter } from 'src/helpers/formatter';
import { useModal } from '@contexts/ModalContext';
import { OrderType } from '@api/userApi';
import Modal, { ModalAction } from '@components/topstep/modal';
import { ButtonType } from '@components/topstep/button';
import Heading from '@components/topstep/heading';
import { DomLayoutType, useSettings } from '@contexts/SettingsContext';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { orderTypeMap } from '@/data/enumTypeMaps';
import Decimal from 'decimal.js';
import { convertToFractional } from '@/helpers/decimalHelper';

interface DomRowProps {
  domIndex: number;
  style: any;
  center: () => void;
}

const DomRow: React.FC<DomRowProps> = ({ domIndex, style, center }): JSX.Element => {
  const { centeredPrice, positionEntryPrice, lastPrice, high, low, hasBidOrders, hasAskOrders, orders, contract, orderAmount, autoCenter, maxDomPrices, tick } = useDom();
  const { showConfirmations: confirmation, customSettings } = useSettings();
  const { placeOrderWithSymbol } = useOrders();
  const { activeTradingAccount } = useTradingAccount();
  const { symbols } = useSymbol();

  const price = useMemo(() => {
    const priceIndex = (domIndex - maxDomPrices) * -1;
    const tickPrice = new Decimal(tick);
    return new Decimal(centeredPrice ?? 0).add(tickPrice.mul(priceIndex)).toNumber();
  }, [domIndex, centeredPrice]);

  const isPosEntryPrice = useMemo(() => {
    return positionEntryPrice == price;
  }, [price, positionEntryPrice, maxDomPrices, tick]);

  const { showModal, hideModal } = useModal();

  const isHigh = useMemo(() => {
    return price == high;
  }, [price, high]);

  const customFontSize = useMemo(() => {
    if (customSettings.domFontSize == 0) {
      return { fontSize: 10 };
    } else if (customSettings.domFontSize == 1) {
      return { fontSize: 12 };
    } else if (customSettings.domFontSize == 2) {
      return { fontSize: 14 };
    } else {
      return { fontSize: 12 };
    }
  }, [customSettings.domFontSize]);

  const formatter = useMemo(() => {
    return getPriceDigitFormatter(contract.decimalPlaces);
  }, [contract.decimalPlaces]);

  const isLow = useMemo(() => {
    return price == low;
  }, [price, low]);

  const isLast = useMemo(() => {
    return lastPrice == price;
  }, [lastPrice, price]);

  const confirmModal = useCallback(
    (type: OrderType, side: OrderPromptType, symbol, price: number, onConfirm: () => void) => {
      const isSell = side == OrderPromptType.Sell;
      const symbolMetadata = symbols.find((x) => x.symbol == symbol);

      const actions: ModalAction[] = [
        {
          label: 'Cancel',
          props: { buttonType: ButtonType.outline, className: styles.modalButton },
          onClick: hideModal
        },
        {
          label: `Confirm ${isSell ? 'Sell' : 'Buy'}`,
          props: {
            buttonType: isSell ? ButtonType.red : ButtonType.green,
            className: styles.modalButton
          },
          onClick: () => {
            hideModal();
            onConfirm();
          }
        }
      ];
      return (
        <Modal header={<Heading className={isSell ? styles.redHeader : styles.greenHeader}>Confirm Order</Heading>} actions={actions}>
          <div>
            I want to place a {orderAmount > 0 ? '+' : ''}
            {orderAmount} {orderTypeMap[type]} order{' '}
            <span className={styles.bold}>
              {(symbolMetadata?.friendlyName || ' ').substring(1)}
              {symbolMetadata?.maturityMonthYear} ({symbolMetadata?.fullName})
            </span>{' '}
            at {type == OrderType.Market ? 'market price' : <span className={classNames(styles.number, styles.bold)}>{formatContractPrice(price,symbolMetadata)}</span>}
          </div>
        </Modal>
      );
    },
    [orderAmount, symbols]
  );

  const placeOrder = useCallback(
    (price: number, type: OrderPromptType, orderType: OrderType) => {
      if (activeTradingAccount.isFollower === true) return;
      //  if (!orders.get(price)) {
      if (confirmation) {
        showModal(
          confirmModal(orderType, type, contract.symbol, price, () => {
            placeOrderWithSymbol({
              type: orderType,
              amount: orderAmount,
              limitPrice: orderType == OrderType.Limit ? price : undefined,
              stopPrice: orderType == OrderType.Stop ? price : undefined,
              orderType: type,
              symbol: contract.symbol
            });
          })
        );
      } else {
        placeOrderWithSymbol({
          type: orderType,
          amount: orderAmount,
          limitPrice: orderType == OrderType.Limit ? price : undefined,
          stopPrice: orderType == OrderType.Stop ? price : undefined,
          orderType: type,
          symbol: contract.symbol
        });
      }
    },
    [orders, confirmation, placeOrderWithSymbol, confirmModal, contract, activeTradingAccount.isFollower, orderAmount]
  );

  const bidOrderCell = useMemo(() => {
    if (hasBidOrders || customSettings.domHeaderShowMyBidAlways !== false) {
      return <OrderCell canTrade={!activeTradingAccount.isFollower} price={price} orderType={OrderPromptType.Buy} />;
    }
  }, [hasBidOrders, customSettings.domHeaderShowMyBidAlways, activeTradingAccount.isFollower, price]);

  const askOrderCell = useMemo(() => {
    if (hasAskOrders || customSettings.domHeaderShowMyAskAlways !== false) {
      return <OrderCell canTrade={!activeTradingAccount.isFollower} price={price} orderType={OrderPromptType.Sell} />;
    }
  }, [hasAskOrders, customSettings.domHeaderShowMyAskAlways, activeTradingAccount.isFollower, price]);

  const pnlCell = useMemo(() => {
    return customSettings.domHeaderHidePNLColumn !== true && <PnlCell price={price} />;
  }, [customSettings.domHeaderHidePNLColumn, price]);

  const volumeCell = useMemo(() => {
    return customSettings.domHeaderVolProfileHide !== true && <VolumeCell price={price} />;
  }, [customSettings.domHeaderVolProfileHide, price]);

  const bidCell = useMemo(() => {
    return <BidCell orderType={OrderPromptType.Buy} price={price} placeOrder={placeOrder} domLayoutType={customSettings.domLayoutType} bgColor={customSettings.domBidBGColorFriendly} />;
  }, [price, placeOrder, customSettings.domLayoutType, customSettings.domBidBGColorFriendly]);

  const askCell = useMemo(() => {
    return <AskCell orderType={OrderPromptType.Sell} price={price} placeOrder={placeOrder} domLayoutType={customSettings.domLayoutType} bgColor={customSettings.domAskBGColorFriendly}/>;
  }, [price, placeOrder, customSettings.domLayoutType, customSettings.domAskBGColorFriendly]);

  const priceCell = useMemo(() => {
    return (
      <div style={{ fontSize: customFontSize.fontSize }} className={classNames(styles.priceCell, isLast && styles.center, !isLast && isPosEntryPrice && styles.entryPriceCell)} onDoubleClick={center}>
        {contract.fractionalPrice ? convertToFractional(price, contract.priceScale, contract.minMove, contract.minMove2) : formatter.format(price)}
      </div>
    );
  }, [price, isPosEntryPrice, isLast, center, formatter, customFontSize, contract.fractionalPrice, contract.minMove, contract.minMove2]);

  const rowClassNames = useMemo(() => {
    return classNames(styles.domRow, isHigh && styles.highDomRow, isLow && styles.lowDomRow);
  }, [isHigh, isLow]);

  return useMemo(() => {
    return (
      <div style={style} className={isPosEntryPrice ? styles.positionEntryPrice : ''} key={domIndex}>
        <div className={rowClassNames} style={customFontSize}>
          {bidOrderCell}
          {bidCell}
          {priceCell}
          {askCell}
          {askOrderCell}
          {pnlCell}
          {volumeCell}
        </div>
      </div>
    );
  }, [domIndex, price, price, style, isPosEntryPrice, bidOrderCell, bidCell, priceCell, askCell, askOrderCell, pnlCell, volumeCell, formatter, customFontSize, rowClassNames, customSettings.domBidBGColorFriendly, customSettings.domAskBGColorFriendly]);
};

export default React.memo(DomRow);
