import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, Tooltip } from '@mui/material';
import { TsModal } from '../modal';
import { useSettings } from '@/contexts/SettingsContext';
import KeyboardListener from './keyboardListener';
import { set } from 'lodash';

export enum HotkeyActions {
  BuyMarket = 'Buy Market',
  SellMarket = 'Sell Market',
  JoinBid = 'Join Bid',
  JoinAsk = 'Join Ask',
  ReversePosition = 'Reverse Position',
  ClosePosition = 'Close Position',
  CancelAll = 'Cancel All',
  FlattenAll = 'Flatten All',
  HideVolProfileColumn = 'Hide Vol. Profile Column',
  CenterOnLastPrice = 'Center on Last Price',
  ContractSizeUp = 'Contract Size +1',
  ContractSizeDown = 'Contract Size -1',
  BreakEven = 'Break Even',
}

const actions = [
  HotkeyActions.BuyMarket,
  HotkeyActions.SellMarket,
  HotkeyActions.JoinBid,
  HotkeyActions.JoinAsk,
  HotkeyActions.ReversePosition,
  HotkeyActions.ClosePosition,
  HotkeyActions.CancelAll,
  HotkeyActions.FlattenAll,
  HotkeyActions.HideVolProfileColumn,
  HotkeyActions.CenterOnLastPrice,
  HotkeyActions.ContractSizeUp,
  HotkeyActions.ContractSizeDown,
  HotkeyActions.BreakEven,
] as const;

type KeyAction = (typeof actions)[number];

export type Hotkeys = {
  [key in HotkeyActions]: string[];
};

const HotkeySettings = () => {
  const { customSettings, saveCustomSettings } = useSettings();

  const emptyHotkeys = {
    [HotkeyActions.BuyMarket]: [],
    [HotkeyActions.SellMarket]: [],
    [HotkeyActions.JoinBid]: [],
    [HotkeyActions.JoinAsk]: [],
    [HotkeyActions.ReversePosition]: [],
    [HotkeyActions.ClosePosition]: [],
    [HotkeyActions.CancelAll]: [],
    [HotkeyActions.FlattenAll]: [],
    [HotkeyActions.HideVolProfileColumn]: [],
    [HotkeyActions.CenterOnLastPrice]: [],
    [HotkeyActions.ContractSizeUp]: [],
    [HotkeyActions.ContractSizeDown]: [],
    [HotkeyActions.BreakEven]: [],
  };

  const initialHotkeys: Hotkeys = customSettings?.hotkeyBindings ?? emptyHotkeys;

  const [hotkeys, setHotkeys] = useState<Hotkeys>(initialHotkeys);
  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrentAction] = useState<KeyAction | null>(null);
  const [pressedKeys, setPressedKeys] = useState<string[]>([]);
  const keysPressed = useRef(new Set<string>());

  const [invalidMessage, setInvalidMessage] = useState<string | null>(null);

  const handleOpenModal = (action: KeyAction) => {
    setCurrentAction(action);
    setPressedKeys(customSettings.hotkeyBindings[action] ?? []);
    setShowModal(true);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.metaKey) return;
    e.preventDefault();
    const key = e.key.toUpperCase();
    keysPressed.current.add(key);
    setPressedKeys(Array.from(keysPressed.current));
  };

  const handleClear = () => {
    setPressedKeys([]);
    keysPressed.current.clear();
  };

  const handleCloseModal = () => {
    if (currentAction) {
      setHotkeys((prev) => ({
        ...prev,
        [currentAction]: pressedKeys
      }));
    }
    setShowModal(false);
    handleClear();
    setCurrentAction(null);
  };

  const handleRemoveHotkey = (action: KeyAction) => {
    setHotkeys((prev) => ({
      ...prev,
      [action]: []
    }));
  };

  useEffect(() => {
    // Save hotkeys to custom settings
    saveCustomSettings({ hotkeyBindings: hotkeys });
  }, [hotkeys]);

  useEffect(() => {
    if (showModal) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showModal]);

  useEffect(() => {
    if (pressedKeys.length === 0) {
      setInvalidMessage(null);
      return;
    }

    const validActions = Object.keys(emptyHotkeys);

    for (let [key, value] of Object.entries(customSettings.hotkeyBindings)) {
      // Make sure action is valid
      if (validActions.includes(key)) {
        if (key !== currentAction) {
          // check by sorting keys in same order and then joining
          const current = pressedKeys.join('+');
          const saved = value.join('+');

          if (current === saved) {
            setInvalidMessage('Already in use');
            return;
          }

          for (const k of saved.split('+')) {
            if (k === current) {
              setInvalidMessage(`Already in use (contained by ${saved})`);
              return;
            }
          }
        }
      }
    }

    setInvalidMessage(null);
  }, [pressedKeys]);

  useEffect(() => {
    console.log(invalidMessage);
  }, [invalidMessage]);

  const hIcon = () => (
    <Box
      display='inline-flex'
      component='span'
      minWidth={15}
      minHeight={15}
      maxWidth={15}
      maxHeight={15}
      sx={{ border: `1px solid #df981c`, cursor: 'pointer' }}
      borderRadius='2px'
      marginLeft='0.2em'
      marginRight='0.2em'
      justifyContent='center'
      alignItems='center'
    >
      <Tooltip title='Enable Hotkeys' style={{ color: 'white' }}>
        <span style={{ padding: 0, margin: 0, color: '#df981c' }}>H</span>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <Typography variant='body1' textAlign='center' fontSize='1.3em'>
        After creating a hotkey, click the {hIcon()} icon at the top left of the component on the trading page. For more details on Hotkeys, click <a href='https://help.projectx.com/settings/trading-hotkeys' target='_blank'>here.</a>
      </Typography>
      <Box padding={3} overflow='auto'>
        {actions.map((action) => (
          <Box key={action} display='flex' alignItems='center' paddingTop={0.5} paddingBottom={0.5} style={{ borderBottom: '1px solid #1c1e23' }}>
            <Typography color='white' variant='body1' style={{ flexGrow: 1 }}>
              {action}
            </Typography>
            {hotkeys[action]?.length > 0 && (
              <Box component='span' display='inline-block' minWidth='40px' paddingX='5px' height='30px' border='1px solid grey' borderRadius='5px' textAlign='center' lineHeight='30px' marginRight={2} padding='0 10px'>
                {hotkeys[action].join(' + ')}
              </Box>
            )}

            <Button variant='contained' onClick={() => handleOpenModal(action)} size='small'>
              Set Hotkey
            </Button>
            {/* {hotkeys[action].length > 0 && (
              <Button sx={{ marginLeft: 2 }} variant='outlined' onClick={() => handleRemoveHotkey(action)}>
                Clear
              </Button>
            )} */}
          </Box>
        ))}
        <TsModal open={showModal} onClose={() => setShowModal(false)}>
          <Box textAlign='center'>
            <Typography color='white' variant='h6'>
              Press keys for {currentAction}
            </Typography>
            <Box component='span' display='flex' flexDirection='row' justifyContent='center' marginTop='2em'>
              {pressedKeys.map((key) => (
                <Box key={key} component='span' display='inline-block' minWidth='30px' paddingX='5px' height='30px' border='1px solid grey' borderRadius='5px' textAlign='center' lineHeight='30px' margin='0 2px'>
                  {key}
                </Box>
              ))}
            </Box>
            {invalidMessage && <p style={{ marginTop: '12px', marginBottom: 0 }}>{invalidMessage}</p>}
            <Box marginTop='2em' width='100%' display='flex' justifyContent='center' gap='2em'>
              <Button disabled={!!invalidMessage} style={{ width: '25%' }} onClick={handleCloseModal}>
                Confirm
              </Button>
              <Button style={{ width: '25%' }} onClick={handleClear}>
                Clear
              </Button>
            </Box>
          </Box>
        </TsModal>
      </Box>
    </>
  );
};

export default HotkeySettings;
