import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DefaultTsModal } from '@/components/modal';
import { Box, Button, Dialog, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useApi } from '@/contexts/ApiContext';
import { DeleteJournalLogRequest, GetJournalLogDayRequest, GetTradesForDayRequest, ITradeModel, JournalLog, JournalLogUpsertRequest, JournalUpsertResult, TradeModel, TradesInRangeRequest, TradeSummary } from '@/api/userApi';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import PerformanceTradesGrid from './performanceTradesGrid';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quilljs';
import ImageUploader from "quill-image-uploader";
import { set } from 'lodash';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import JournalEditor from './journalEditor';

dayjs.extend(utc);
dayjs.extend(timezone);

type WeekPerformanceModalProps = {
  accountId: number;
  startDate: Dayjs;
  endDate: Dayjs;
  show: boolean;
  onHide: () => void;
  onJournalAdded: (journal: JournalLog) => void;
  onJournalRemoved: (journal: JournalLog) => void;
};

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const formatDollarPrice = (value?: number): string => {
  if (isNaN(value)) return '';
  return priceFormatter.format(value);
};

const getDollarTextFromValue = (value: number) => {
  const formatted = formatDollarPrice(value);
  if (value > 0) {
    return <span style={{ color: '#56d660' }}>{formatted}</span>;
  } else if (value < 0) {
    return <span style={{ color: '#d6565e' }}>{formatted}</span>;
  } else {
    return <span>{formatted}</span>;
  }
};

const profitLineChart = (data: any) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        title: {
          text: ''
        },
        xAxis: {
          title: {
            text: 'Time',
            type: 'datetime',
            style: {
              color: '#FFFFFF',
              fontSize: '1.1em'
            }
          },
          tickInterval: 24 * 3600 * 1000, // One day
          tickPositioner: function() {
            const start = this.dataMin;
            const end = this.dataMax;
            const positions = [];
            for (let tick = start; tick <= end; tick += this.options.tickInterval) {
              positions.push(tick);
            }
            return positions;
          },
          labels: {
            style: {
              color: '#FFFFFF',
              fontSize: '1.1em'
            },
            formatter: function () {
              return dayjs(this.value).tz(dayjs.tz.guess()).format('ddd');
            }
          },
        },
        yAxis: {
          gridLineColor: '#3d3c43',
          title: {
            text: 'Profit',
            style: {
              color: '#FFFFFF',
              fontSize: '1.1em'
            }
          },
          labels: {
            style: {
              color: '#FFFFFF',
              fontSize: '1.1em'
            }
          }
        },
        tooltip: {
          backgroundColor: '#000000', // Set tooltip background color to black
          style: {
            color: '#FFFFFF' // Set text color to white
          },
          useHTML: true, // Enable HTML in the tooltip
          headerFormat: '<span style="color: #FFFFFF;">Week Cumulative Profit</span><br/>', // Custom title for the tooltip
          pointFormat: 'Date: {point.datetime}<br>Profit: {point.text}'
        },
        series: [
          {
            type: 'area',
            data: data,
            zones: [
              {
                value: 0, // Anything below 0 (negative values)
                color: '#d6565e',
                fillColor: 'rgba(214, 86, 96, 0.2)' // Light red shading
              },
              {
                color: '#56d660', // Positive values
                fillColor: 'rgba(86, 214, 96, 0.2)' // Light green shading
              }
            ],
            zoneAxis: 'y'
          }
        ],
        chart: {
          backgroundColor: 'transparent',
          width: null,
          style: {
            width: '100%'
          },
          height: 350 // Set the max height here (e.g., 400 pixels)
        },
        legend: {
          enabled: false // Remove the series legend
        },
        credits: {
          enabled: false
        }
      }}
    />
  );
};

const WeekPerformanceModal = (props: WeekPerformanceModalProps) => {
  const { statisticsApi, journalLogApi, tradeApi, userDataUploadApi } = useApi();
  const [dayTradeData, setDayTradeData] = React.useState<TradeModel[]>([]);
  const { activeTradingAccount } = useTradingAccount();

  const [dayJournal, setDayJournal] = React.useState<JournalLog>(null);
  const [dayJournalText, setDayJournalText] = React.useState<string>('');
  const [tradeJournals, setTradeJournals] = React.useState<JournalLog[]>([]);
  const [showJournal, setShowJournal] = useState(!!dayJournal?.content);

  useEffect(() => {
    if (dayJournal) {
      setDayJournalText(dayJournal.content);
    }
  }, [dayJournal, showJournal]);

  const journalChanged = useMemo(() => {
    return dayJournalText !== dayJournal?.content ?? '';
  }, [dayJournalText, dayJournal]);

  useEffect(() => {
    tradeApi.getInRange(new TradesInRangeRequest({
      tradingAccountId: props.accountId,
      start: props.startDate,
      end: props.endDate
    })).then((response) => {
        setDayTradeData(response.sort((a, b) => a.createdAt.diff(b.createdAt)));
      })
      .catch((error) => {});

    journalLogApi
      .getForDay(
        new GetJournalLogDayRequest({
          tradingAccountId: props.accountId,
          tradeDay: props.endDate
        })
      )
      .then((response) => {
        // get the one journal log that does not have a trade id
        const journal = response.find((j) => j.tradeId === null);
        setDayJournal(journal);

        // make new list with everything but the journal log that does not have a trade id
        const tradeJournals = response.filter((j) => j.tradeId !== null);
        setTradeJournals(tradeJournals);
      });
  }, [props.show, props.endDate, props.accountId]);

  const handleSaveDayJournal = (data: string) => {
    const promise = new Promise<void>((resolve, reject) => {
      journalLogApi
        .upsert(
          new JournalLogUpsertRequest({
            content: data,
            tradeDay: props.endDate,
            tradingAccountId: props.accountId,
            tradeId: null
          })
        )
        .then((res) => {

          if (!res.success) {
            reject();
            return;
          }

          resolve();

          const log = {
            content: data,
            tradeDay: props.endDate,
            tradingAccountId: props.accountId
          } as JournalLog;

          setDayJournal(log); // update the journal log
          props.onJournalAdded(log); // update the journal log in the parent component
        })
        .catch((error) => {
          reject();
        });
    });

    toast.promise(promise, {
      pending: 'Saving journal...',
      success: 'Journal saved',
      error: 'Error saving journal'
    });
  };

  const handleDeleteDayJournal = () => {
    const promise = new Promise<void>((resolve, reject) => {
      journalLogApi
        .delete(
          new DeleteJournalLogRequest({
            tradeDay: props.endDate,
            tradingAccountId: props.accountId,
            tradeId: null
          })
        )
        .then((res) => {
          if (!res) {
            reject();
            return;
          }

          props.onJournalRemoved(dayJournal);

          resolve();
          setDayJournal(null);
          setDayJournalText('');
        })
        .catch((error) => {
          reject();
        });
    });

    toast.promise(promise, {
      pending: 'Deleting journal...',
      success: 'Journal deleted',
      error: 'Error deleting journal'
    });
  };

  const tradeCumulativeData = useMemo(() => {
    // Group data by date
    const groupedByDate = dayTradeData.reduce((acc, stat) => {
      const dateKey = stat.tradeDay.startOf('day').format('YYYY-MM-DD');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(stat);
      return acc;
    }, {});
  
    // Calculate daily P&L
    const dailyPnLData = Object.keys(groupedByDate).map(dateKey => {
      const dailyStats = groupedByDate[dateKey];
      const dailyPnL = dailyStats.reduce((acc, stat) => acc + stat.pnL - stat.fees, 0);
      return {
        date: dayjs(dateKey),
        dailyPnL
      };
    });
  
    // Each data point is the sum of all previous daily P&L plus the current daily P&L
    return dailyPnLData.map((stat, index) => {
      const previousData = dailyPnLData.slice(0, index);
      const previousSum = previousData.reduce((acc, prev) => acc + prev.dailyPnL, 0);
      return {
        x: stat.date.toDate(),
        y: previousSum + stat.dailyPnL,
        text: (previousSum + stat.dailyPnL).toFixed(2),
        color: previousSum + stat.dailyPnL > 0 ? '#56d660' : '#d6565e',
        name: getDollarTextFromValue(previousSum + stat.dailyPnL),
        datetime: stat.date.tz(dayjs.tz.guess()).format('dddd, MMMM D, YYYY')
      };
    });
  }, [dayTradeData]);
  const journalBox = useMemo(() => {
    if (props.accountId != activeTradingAccount?.accountId) {
      return null;
    }

    // Show even if there are no trades
    // if (!dayTradeData || dayTradeData.length == 0) {
    //  return null;
    //}

    console.log('DayJournal', dayJournal, showJournal);
    if (showJournal || !!dayJournal?.content) {
      console.log('Show Journal');
      return (
        <>
          <Typography textAlign={'center'} margin='0.5em 0' variant='h4' color='white'>
            Week Journal
          </Typography>
          <JournalEditor 
            initialValue={dayJournalText}
            onChange={(content) => setDayJournalText(content)}
          />
          <Button sx={{ marginLeft: '0.5em', marginTop: '0.5em', marginBottom: '2em' }} onClick={() => handleSaveDayJournal(dayJournalText)} variant='contained' color='primary'>
            Save Journal
          </Button>
          {dayJournal && (
            <Button variant='contained' sx={{ marginLeft: '1em', marginTop: '0.5em', marginBottom: '2em' }} color='warning' onClick={() => { handleDeleteDayJournal(); }}>
              Delete Journal
            </Button>
          )}
          {journalChanged && (
            <Button variant='contained' sx={{ marginLeft: '1em', marginTop: '0.5em', marginBottom: '2em' }} color='error' onClick={() => { setShowJournal(false); setDayJournalText(dayJournal?.content || ''); }}>
              Cancel
            </Button>
          )}
        </>
      );
    } else {
      console.log('Show Add Journal');
      return (
        <Box sx={{ width: '100%', alignItems: 'center', alignContent: 'center', textAlign: 'center' }}>
          <Typography margin='0.5em 0' variant='h4' color='white'>
            Week Journal
          </Typography>
          <Typography margin='0.5em 0' variant='body1' color='white'>
            No journal for this week. Add a journal to keep track of your thoughts and trades for the week.
          </Typography>
          <Button sx={{ marginTop: '1em', marginBottom: '2em' }} onClick={() => setShowJournal(true)} variant='contained' color='primary'>
            Add Journal
          </Button>
        </Box>
      );
    }
  }, [props.accountId, activeTradingAccount, dayTradeData, dayJournal, tradeJournals, showJournal, dayJournalText, journalChanged, handleSaveDayJournal]);

  return (
    <Dialog open={props.show} onClose={props.onHide} maxWidth={false}>
      <Box padding='2em'>
        <Typography marginBottom='0.5em' variant='h5' color='white'>
          Week Performance: {props.startDate.format('MM/DD/YY')} - {props.endDate.format('MM/DD/YY')}
        </Typography>
        <Box sx={{ fontSize: '1.5em' }}>{profitLineChart(tradeCumulativeData)}</Box>
        {/* {journalBox} */}
        <PerformanceTradesGrid onJournalRemoved={props.onJournalRemoved} onJournalAdded={props.onJournalAdded} height={300} accountId={props.accountId} trades={dayTradeData} startDate={props.startDate.startOf('day')} endDate={props.endDate.endOf('day')} />
      </Box>
    </Dialog>
  );
};

export default WeekPerformanceModal;
